import { Icon } from '@chakra-ui/react';

const IconCustomArtStyle = ({ color }: { color?: string }) => (
  <Icon
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 9V7H16V2.84C14.7363 2.28427 13.3705 1.99818 11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 10.95 21.83 9.95 21.53 9H18ZM15.5 8C16.33 8 17 8.67 17 9.5C17 10.33 16.33 11 15.5 11C14.67 11 14 10.33 14 9.5C14 8.67 14.67 8 15.5 8ZM8.5 8C9.33 8 10 8.67 10 9.5C10 10.33 9.33 11 8.5 11C7.67 11 7 10.33 7 9.5C7 8.67 7.67 8 8.5 8ZM12 17.5C9.67 17.5 7.69 16.04 6.89 14H17.11C16.31 16.04 14.33 17.5 12 17.5ZM22 3H24V5H22V7H20V5H18V3H20V1H22V3Z"
      fill={color || '#A3AED0'}
    />
  </Icon>
);

export default IconCustomArtStyle;
