import { buttonStyles } from './button';

export const components = {
  Heading: {
    baseStyle: {
      color: '#000000',
      fontWeight: 700,
    },
  },
  ...buttonStyles,
};
