import { Text, VStack, useToast } from '@chakra-ui/react';

import { imageToUpscale } from '@/api/image-generator';

import Button from '@/lib/components/Button';

import { downloadFromUrl } from '@/lib/utils/download';
import { Design, DesignSide, HttpStatusCode } from '@/lib/types';

import DownloadResolutionPicker from './DownloadResolutionPicker';
import OutOfCreditsModal from '@/components/subscription/OutOfCreditsModal';
import SidePicker from './SidePicker';
import IconCredits from '@/lib/editor/icons/IconCredits';
import { useState } from 'react';
import { useMe } from '@/api/auth';

interface DownloadDesignFormProps {
  design: Design;
  selectedResolution: number;
  onChangeSelectedResolution: (resolution: number) => void;
  creditsForUpscaling: number;
  originalImageWidth: number;
  originalImageHeight: number;
  resolutionMultipliers: number[];
}

export default function DownloadDesignForm({
  design,
  originalImageHeight,
  originalImageWidth,
  selectedResolution,
  onChangeSelectedResolution,
  creditsForUpscaling,
  resolutionMultipliers,
}: DownloadDesignFormProps) {
  const [selectedSide, setSelectedSide] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOutOfCreditsModalVisible, setOutOfCreditsModalVisible] = useState(false);

  const { data: me } = useMe();

  const { sides: allSides = [] } = design || {};

  const sides = allSides.filter(({ canvasState }) => !!canvasState);

  const designSide =
    design && ((sides.find(({ id }) => id === selectedSide) || sides[0]) as DesignSide);

  const { designImage: imageUrl } = designSide || {};

  const toast = useToast();

  const handleDownload = async () => {
    if (me.client.credits < costInCredits) {
      setOutOfCreditsModalVisible(true);

      return;
    }

    setLoading(true);

    try {
      if (selectedResolution === resolutionMultipliers[0]) {
        await downloadFromUrl(imageUrl, `${designSide?.templateSide?.name}`);

        return;
      }

      const res = await fetch(imageUrl);
      const blob = await res.blob();

      const file = new File([blob], 'File name', { type: 'image/png' });

      const upscaledUrl = await imageToUpscale(file, selectedResolution);

      await downloadFromUrl(upscaledUrl, `${designSide?.templateSide?.name}`);
    } catch (e) {
      if (e.response?.status === HttpStatusCode.PaymentRequired) {
        setOutOfCreditsModalVisible(true);
      } else {
        toast({
          position: 'top',
          title: 'Error downloading',
          status: 'error',
          duration: 2000,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const costInCredits = selectedResolution === resolutionMultipliers[0] ? 0 : creditsForUpscaling;

  return (
    <>
      <VStack align="flex-start" spacing="20px" w={{ base: '100%', md: '350px' }}>
        <Text fontSize="20px" fontWeight={600}>
          Download Image as PNG
        </Text>
        <DownloadResolutionPicker
          imageWidth={originalImageWidth}
          imageHeight={originalImageHeight}
          resolutionMultipliers={resolutionMultipliers}
          selectedValue={selectedResolution}
          onSelectedValue={onChangeSelectedResolution}
        />
        {sides.length ? (
          <SidePicker
            sides={sides}
            selectedSide={selectedSide || sides[0].id}
            onSelectedSide={setSelectedSide}
          />
        ) : null}
        <Button
          fontSize="14px"
          isLoading={loading}
          loadingText="Preparing image"
          onClick={handleDownload}
          _hover={{
            color: '#FFFFFF',
          }}
          w="100%"
        >
          <Text mr={costInCredits ? '6px' : '3px'}>Download</Text>
          {costInCredits ? <IconCredits /> : null}
          <Text>{costInCredits || 'Free'}</Text>
        </Button>
      </VStack>
      {isOutOfCreditsModalVisible ? (
        <OutOfCreditsModal onClose={() => setOutOfCreditsModalVisible(false)} />
      ) : null}
    </>
  );
}
