import axios from 'axios';

export const removeBackgroundByUrl = (imageUrl: string): Promise<string> =>
  axios
    .post('/background-removal/remove-background-by-url', {
      imageUrl,
    })
    .then(({ data }) => {
      return data.image;
    });

export const removeBackgroundByFile = async (imageFile: string): Promise<string> => {
  const response = await axios.post(
    '/background-removal/remove-background-by-image',
    {
      imageFile,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  return response.data.image;
};
