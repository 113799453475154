import { useState } from 'react';

import {
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  VStack,
  Text,
  useToast,
  HStack,
} from '@chakra-ui/react';

import { useCreateInvites } from '@/api/users';
import IconCloseModalSmall from '@/components/icons/IconCloseModal';

import Button from '@/components/button';

import { AxiosError } from 'axios';
import FormInput from '../../../components/form/FormInput';
import ButtonAddListItem from '../template-library/create-template/components/ButtonAddListItem';
import ButtonDelete from '../template-library/create-template/components/ButtonDelete';

// Just a helper email validation - real validation is done server-side
const REGEX_EMAIL: RegExp = /^\S+@\S+\.\S+$/;

type Props = {
  onClose: () => void;
};

function InviteModal({ onClose }: Props) {
  const [emails, setEmails] = useState<string[]>(['']);

  const [waiting, setWaiting] = useState(false);

  const toast = useToast();

  const { createInvites } = useCreateInvites();

  const handleEmailUpdate = (email: string, index: number) => {
    emails[index] = email;
    setEmails([...emails]);
  };

  const handleAddEmail = () => {
    setEmails([...emails, '']);
  };
  const handleRemoveEmail = (index) => {
    emails.splice(index, 1);
    setEmails([...emails]);
  };

  const handleInvite = () => {
    let errorMessage = null;
    if (!emails.length) {
      errorMessage = 'Please enter at least one email';
    }
    for (const email of emails) {
      if (!REGEX_EMAIL.test(email)) {
        errorMessage = `Invalid email address: ${email}`;
        break;
      }
    }
    if (errorMessage) {
      toast({
        title: errorMessage,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setWaiting(true);
    createInvites(emails, {
      onSuccess: () => {
        toast({
          title: 'Invites created',
          status: 'success',
          duration: 2000,
        });
        setWaiting(false);
        onClose();
      },
      onError: (err: AxiosError) => {
        let message = 'Error saving changes';
        if (err?.response?.data) {
          const errors = err?.response?.data['message'];
          if (errors && errors.length) {
            let errorText = '';
            if (Array.isArray(errors)) {
              errorText = errors.join(', ');
            } else {
              errorText = errors;
            }
            message += `: ${errorText}`;
          }
        }

        toast({
          title: message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });

        setWaiting(false);
      },
    });
  };
  return (
    <Modal isOpen={true} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent alignSelf="center" borderRadius="10px" maxWidth="680px">
        <ModalBody padding="50px 66px">
          <Flex alignItems="center" justifyContent="space-between">
            <Box>
              <Text as="b" color="black.700" fontSize="26px" fontWeight={700} mb="12px">
                Invite your team
              </Text>
            </Box>
            <Button bg="transparent" minWidth="none" onClick={onClose} padding={0} tabIndex={-1}>
              <IconCloseModalSmall />
            </Button>
          </Flex>
          <VStack align="flex-start" justify="space-between" pt="30px" spacing={6}>
            <VStack spacing={2}>
              {emails.map((email, index) => (
                <HStack>
                  <FormInput
                    key={index}
                    width="476px"
                    placeholder="Add email here"
                    value={email}
                    onChange={(e) => handleEmailUpdate(e.target.value, index)}
                  />
                  {emails.length > 1 && <ButtonDelete onClick={() => handleRemoveEmail(index)} />}
                </HStack>
              ))}
            </VStack>
            <ButtonAddListItem onClick={handleAddEmail}>+ Add more</ButtonAddListItem>
            <Button
              borderRadius="20px"
              isLoading={waiting}
              isDisabled={!emails.length}
              onClick={handleInvite}
              w="128px"
            >
              Invite
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default InviteModal;
