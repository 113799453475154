import { Box, HStack } from '@chakra-ui/react';

import EmptyState from '@/components/empty-state';
import { Template } from '@/components/types';
import { Design } from '@/lib/types';

import DesignCard from './DesignCard';

type DesignsListProps = {
  designs: Design[];
  onDesignDeleted: (id: string) => void;
  onDesignUpdated: (updates: Partial<Design>) => void;
  onSelectedDesign: (design: Design) => void;
  templates: Template[];
};

const DesignsList = ({
  designs,
  onDesignUpdated,
  onDesignDeleted,
  onSelectedDesign,
}: DesignsListProps) => {
  if (!designs.length) {
    return <EmptyState onAdd={() => {}} message="No designs found" />;
  }

  return (
    <Box>
      <HStack spacing="16px" wrap="wrap">
        {designs.map((design) => (
          <DesignCard
            key={design.id}
            design={design}
            onDesignDeleted={onDesignDeleted}
            onDesignUpdated={onDesignUpdated}
            onDesignSelected={onSelectedDesign}
          />
        ))}
      </HStack>
    </Box>
  );
};

export default DesignsList;
