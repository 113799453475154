import { Icon } from '@chakra-ui/react';

const IconCredits = () => (
  <Icon
    width="21px"
    height="21px"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.9726 3.62431C6.05085 3.62431 3.68681 6.70422 3.68681 10.5C3.68681 14.2958 6.05085 17.3757 8.9726 17.3757H12.0275C14.9493 17.3757 17.3133 14.2958 17.3133 10.5C17.3133 6.70422 14.9493 3.62431 12.0275 3.62431H8.9726Z"
      fill="#B2E8FF"
    />
    <path
      d="M6.41712 8.4606C6.66684 7.88624 7.01645 7.43674 7.45763 7.12043C7.89881 6.80411 8.40658 6.64595 8.98094 6.64595C9.72178 6.64595 10.3294 6.8707 10.8122 7.31188C11.295 7.75306 11.6113 8.37736 11.7612 9.16815H10.6874C10.5875 8.71033 10.3877 8.35239 10.088 8.09434C9.78837 7.8363 9.42211 7.70311 8.98094 7.70311C8.40658 7.70311 7.94875 7.94451 7.59914 8.43563C7.24953 8.91843 7.07472 9.60933 7.07472 10.5C7.07472 11.3907 7.24953 12.0982 7.59914 12.5893C7.94875 13.0805 8.4149 13.3219 8.98094 13.3219C9.42211 13.3219 9.78837 13.197 10.0797 12.939C10.3711 12.6892 10.5708 12.323 10.6874 11.8485H11.7612C11.603 12.6143 11.2784 13.222 10.7956 13.6798C10.3128 14.1376 9.70513 14.3707 8.98094 14.3707C8.40658 14.3707 7.89881 14.2125 7.45763 13.8962C7.01645 13.5799 6.66684 13.1304 6.41712 12.5477C6.1674 11.965 6.04254 11.2825 6.04254 10.5C6.04254 9.71754 6.1674 9.05161 6.41712 8.47725V8.4606Z"
      fill="#054AC4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.9726 3C7.27774 3 5.78666 3.89492 4.73901 5.25835C3.69192 6.62104 3.0625 8.47654 3.0625 10.5C3.0625 12.5235 3.69192 14.379 4.73901 15.7417C5.78666 17.1051 7.27774 18 8.9726 18H12.0275C13.7224 18 15.2135 17.1051 16.2611 15.7417C17.3082 14.379 17.9376 12.5235 17.9376 10.5C17.9376 8.47654 17.3082 6.62104 16.2611 5.25835C15.2135 3.89492 13.7224 3 12.0275 3H8.9726ZM4.31111 10.5C4.31111 8.72767 4.86371 7.14533 5.72909 6.01912C6.59389 4.89365 7.74571 4.24861 8.9726 4.24861H9.05585V4.25694C10.3148 4.25694 11.4837 4.92062 12.3511 6.04853C13.2187 7.17682 13.7673 8.75267 13.7673 10.5083C13.7673 12.264 13.2187 13.8398 12.3511 14.9681C11.54 16.0228 10.4653 16.6716 9.29979 16.7514H8.9726C7.74571 16.7514 6.59389 16.1064 5.72909 14.9809C4.86371 13.8547 4.31111 12.2723 4.31111 10.5ZM11.6804 16.7514H12.0275C13.2544 16.7514 14.4062 16.1064 15.2711 14.9809C16.1364 13.8547 16.689 12.2723 16.689 10.5C16.689 8.72767 16.1364 7.14533 15.2711 6.01912C14.4062 4.89365 13.2544 4.24861 12.0275 4.24861H11.656C12.162 4.59207 12.6184 5.03071 13.0109 5.54111C13.9996 6.82681 14.5997 8.58475 14.5997 10.5083C14.5997 12.4319 13.9996 14.1899 13.0109 15.4756C12.6248 15.9777 12.1767 16.4105 11.6804 16.7514Z"
      fill="#054AC4"
    />
  </Icon>
);

export default IconCredits;
