import { Button as ChakraButton, HStack, Image } from '@chakra-ui/react';

import NoSelection from './template-svgs/NoSelection.svg';
import PolaroidBlack from './template-svgs/PolaroidBlack.svg';
import Box from './template-svgs/Box.svg';
import Fire from './template-svgs/Fire.svg';
import Hands from './template-svgs/Hands.svg';
import MyPhotos from './template-svgs/MyPhotos.svg';
import PolaroidWhite from './template-svgs/PolaroidWhite.svg';
import QRCodeContainer from './template-svgs/QRCodeContainer.svg';

const OPTIONS = [
  NoSelection,
  PolaroidBlack,
  Fire,
  QRCodeContainer,
  Box,
  MyPhotos,
  PolaroidWhite,
  Hands,
];

const BUTTON_SIZE = 40;

const Button = (props) => (
  <ChakraButton
    bg="#FFFFFF"
    h={`${BUTTON_SIZE}px`}
    flexShrink={0}
    padding="0"
    w={`${BUTTON_SIZE}px`}
    borderRadius="8px"
    minWidth="auto"
    {...props}
  />
);

type Props = {
  onSelectedTemplate: (value: string) => void;
};

const TemplatePicker = ({ onSelectedTemplate }: Props) => {
  const handleSelectedTemplate = (icon) => {
    fetch(icon)
      .then((response) => response.text())
      .then((iconAsSvgString) => onSelectedTemplate(iconAsSvgString));
  };

  return (
    <HStack spacing="4px">
      {OPTIONS.map((icon, index) => {
        return (
          <Button
            key={index}
            onClick={() => {
              if (index === 0) {
                onSelectedTemplate(null);

                return;
              }

              handleSelectedTemplate(icon);
            }}
          >
            <Image src={icon} />
          </Button>
        );
      })}
    </HStack>
  );
};

export default TemplatePicker;
