import axios from 'axios';

import { useQuery } from '@tanstack/react-query';
import { User } from '@/components/types';

import Config from '../config';
import { StorageKeys } from '../constants';
import { handleLogout, identifyUser } from '../analytics';

const { API_URL } = Config;

axios.defaults.baseURL = API_URL;

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem(StorageKeys.ACCESS_TOKEN);

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.headers['Target-URL'] = API_URL;
  config.headers['X-Api-Key'] = localStorage.getItem(StorageKeys.API_KEY);

  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },

  (error) => {
    const { response } = error || {};

    const { pathname } = window.location;

    if (
      response &&
      response.status === 401 &&
      !response.config.url.includes('/auth') &&
      !pathname.includes('/designs/') &&
      pathname !== '/'
    ) {
      localStorage.removeItem(StorageKeys.ACCESS_TOKEN);

      window.location.href = '/auth/signin';
    }

    return Promise.reject(error);
  }
);

export const login = (email: string, password: string) =>
  axios
    .post('/auth/login', {
      email,
      password,
    })
    .then(({ data }) => {
      return data;
    });

export const logout = () => {
  localStorage.removeItem(StorageKeys.ACCESS_TOKEN);

  window.location.href = '/';

  handleLogout();
};

export const googleLogin = (token: string, inviteToken?: string) =>
  axios
    .post('/auth/google/login', {
      token,
      inviteToken,
    })
    .then(({ data }) => {
      return data;
    });

export const signUp = (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  inviteToken?: string
) =>
  axios
    .post('/auth/register', {
      email,
      password,
      firstName,
      lastName,
      inviteToken,
    })
    .then(({ data }) => {
      return data;
    });

export const useMe = () =>
  useQuery(['me'], () =>
    axios.get<User>('/profile').then(({ data }) => {
      identifyUser(data);
      return data;
    })
  );

export const resetPassword = (email: string) =>
  axios.post('/auth/forgot-password', {
    email,
  });

export const setPassword = (password: string, token: string) =>
  axios
    .post('/auth/reset-password', {
      token,
      password,
    })
    .then(({ data }) => data);

export const verifyEmail = (token: string) =>
  axios
    .post('/auth/verify-email', {
      token,
    })
    .then(({ data }) => data);
