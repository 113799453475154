import axios from 'axios';

import { useQuery } from '@tanstack/react-query';

import { CustomStyle } from '@/lib/types';

export interface CreateCustomStyleRequest {
  name: string;
  coverImage: File;
  trainingData: Blob;
}

const entity = 'styles';

const CUSTOM = 'custom';

const getCustomStyles = () =>
  axios.get<CustomStyle[]>(`/${entity}/${CUSTOM}`).then(({ data }) => data);

export const useCustomStyles = () => useQuery([entity, CUSTOM], () => getCustomStyles());

export const createCustomStyle = (params: CreateCustomStyleRequest) =>
  axios
    .post(`/${entity}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => data);
