import { Box, Spinner, Text, VStack } from '@chakra-ui/react';
import Cropper from 'react-easy-crop';
import { IMAGE_WIDTH } from '../toolbar/image-to-image/cropImage';
import Button from '../../components/Button';
import { useState } from 'react';

export interface ICropToolProps {
  crop: { x: number; y: number };
  imageFile: string;
  onCropComplete: (croppedImage: Blob) => void;
  onCropChange: (crop: { x: number; y: number }) => void;
  zoom: number;
  onZoomChange: (zoom: number) => void;
  onSetCrop: () => void;
}

export const CropTool = ({
  crop,
  imageFile,
  onCropComplete,
  onCropChange,
  zoom,
  onSetCrop,
  onZoomChange,
}) => {
  const [isCropping, setIsCropping] = useState(false);

  return (
    <VStack align="start">
      <Text as="b" color="#1A1A1A" fontSize="sm" mt="16px">
        Crop Image
      </Text>
      <Box mb="16px" position="relative" w={`${IMAGE_WIDTH}px`} h={`${IMAGE_WIDTH}px`}>
        <Cropper
          image={imageFile}
          crop={crop}
          cropSize={{
            width: IMAGE_WIDTH,
            height: IMAGE_WIDTH,
          }}
          zoom={zoom}
          onCropChange={onCropChange}
          onZoomChange={onZoomChange}
          onCropComplete={(croppedArea, croppedPixels) => {
            setIsCropping(false);
            onCropComplete(croppedArea, croppedPixels);
          }}
        />
      </Box>
      <Button
        text="Set Crop"
        width="100%"
        onClick={() => {
          setIsCropping(true);
          onSetCrop();
        }}
        mb="3"
        icon={isCropping ? <Spinner /> : null}
      >
        Set Crop
      </Button>
    </VStack>
  );
};
