import { Icon } from '@chakra-ui/react';

const IconTemplate = ({ color }: { color?: string }) => (
  <Icon
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.8525 3.86301L15.297 1.91824C15.2732 1.90994 15.2479 1.90697 15.2229 1.90954C15.1978 1.91211 15.1736 1.92016 15.152 1.93311C15.1304 1.94607 15.1119 1.96361 15.0978 1.98451C15.0838 2.0054 15.0745 2.02913 15.0706 2.05402C14.9098 3.01698 14.4128 3.89172 13.6678 4.52267C12.9228 5.15363 11.9782 5.49989 11.0019 5.49989C10.0256 5.49989 9.08095 5.15363 8.33595 4.52267C7.59096 3.89172 7.09389 3.01698 6.93316 2.05402C6.92939 2.02872 6.92002 2.00457 6.90573 1.98335C6.89144 1.96213 6.8726 1.94437 6.85057 1.93136C6.82854 1.91835 6.80389 1.91042 6.77841 1.90815C6.75292 1.90588 6.72726 1.90933 6.70328 1.91824L1.14785 3.86301C0.992354 3.91744 0.861578 4.0259 0.779324 4.16864C0.69707 4.31138 0.668808 4.47891 0.699683 4.64074L1.41425 8.42199C1.44175 8.56611 1.51464 8.69766 1.62226 8.79739C1.72988 8.89713 1.86658 8.95981 2.01238 8.97629L4.11269 9.21347C4.19843 9.22314 4.27739 9.2647 4.33391 9.32989C4.39043 9.39508 4.42036 9.47915 4.41777 9.56539L4.10281 19.9166C4.09863 20.05 4.13338 20.1817 4.20282 20.2957C4.27225 20.4097 4.37337 20.501 4.49382 20.5585C4.59395 20.6038 4.70278 20.6265 4.81265 20.6251H17.1877C17.2975 20.6265 17.4063 20.6038 17.5065 20.5585C17.6269 20.501 17.7281 20.4097 17.7975 20.2957C17.8669 20.1817 17.9017 20.05 17.8975 19.9166L17.5825 9.56539C17.5799 9.47915 17.6099 9.39508 17.6664 9.32989C17.7229 9.2647 17.8019 9.22314 17.8876 9.21347L19.9879 8.97629C20.1337 8.95981 20.2704 8.89713 20.378 8.79739C20.4857 8.69766 20.5585 8.56611 20.586 8.42199L21.3006 4.64074C21.3315 4.47891 21.3032 4.31138 21.221 4.16864C21.1387 4.0259 21.0079 3.91744 20.8525 3.86301Z"
      fill={color || '#A3AED0'}
    />
  </Icon>
);

export default IconTemplate;
