import { Box, Center, Spinner, VStack, HStack } from '@chakra-ui/react';

import { useMe } from '@/api/auth';
import { useCustomStyles } from '@/api/custom-styles';

import NewStyleCard from './components/NewStyleCard';
import StyleCard from './components/StyleCard';

import { useBillableActions } from '@/api/subscription';
import { BillableActionType } from '@/components/types';

export default function CustomArtStyles() {
  const { data: styles, isLoading } = useCustomStyles();

  const { data: me, isLoading: isLoadingProfile } = useMe();

  const { data: billableActions = [] } = useBillableActions();

  if (isLoading || isLoadingProfile) {
    return (
      <Center width="100%" height="400px">
        <Spinner thickness="1px" speed="0.65s" emptyColor="gray" color="brand.500" size="md" />
      </Center>
    );
  }

  const billableAction = billableActions.find(
    ({ name }) => name === BillableActionType.CUSTOM_ART_STYLE
  );

  const costInCredits = billableAction?.credits;

  return (
    <Box>
      <VStack align="flex-start" padding="50px 12px" spacing="45px">
        <HStack align="flex-start" wrap="wrap" w="100%">
          {styles.map((style, index) => (
            <StyleCard key={index} style={style} />
          ))}
          <NewStyleCard cost={costInCredits} me={me} />
        </HStack>
      </VStack>
    </Box>
  );
}
