import { Box, HStack, VStack, useToast } from '@chakra-ui/react';
import { ReactNode, useState } from 'react';

import { changePassword } from '@/api/users';
import Button from '@/components/button';

import ErrorMessage from '@/views/auth/components/ErrorMessage';
import { getPasswordComplexityErrors } from '@/utils/password';

import FormCard from './FormCard';
import FormInput from '@/components/form/FormInput';

export default function ChangePassword() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<ReactNode>(null);

  const toast = useToast();

  const handleSave = () => {
    setError(null);

    let error;

    if (!oldPassword) {
      error = "Old password can't be empty";
    } else if (!newPassword) {
      error = "New password can't be empty";
    } else if (newPassword !== confirmPassword) {
      error = 'Password have to match';
    } else {
      const complexityErrors = getPasswordComplexityErrors(newPassword);

      if (complexityErrors.length) {
        const errorMessage = complexityErrors.map((error) => (
          <Box>Password has to have at least {error}.</Box>
        ));

        error = errorMessage;
      }
    }

    if (error) {
      setError(error);

      return;
    }

    setIsSaving(true);

    changePassword(oldPassword, newPassword)
      .then(() => {
        toast({
          position: 'top',
          title: 'Changes saved',
          status: 'success',
          duration: 2000,
        });

        setIsSaving(false);
      })
      .catch(() => {
        toast({
          position: 'top',
          title: 'Error updating password',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });

        setIsSaving(false);
      });
  };

  return (
    <FormCard description="Here you can set your new password" title="Change password">
      <VStack align="flex-start" spacing="35px">
        <FormInput
          isPassword
          name="Old Password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
        <HStack spacing="38px">
          <FormInput
            isPassword
            name="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <FormInput
            isPassword
            name="New Password Confirmation"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </HStack>
        {error ? <ErrorMessage message={error} /> : null}
        <Button isLoading={isSaving} onClick={handleSave}>
          Save password
        </Button>
      </VStack>
    </FormCard>
  );
}
