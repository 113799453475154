import {
  Button as ChakraButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem as ChakraMenuItem,
} from '@chakra-ui/react';

import IconMenu from '@/components/icons/IconMenu';

type CardMenuProps = {
  onDelete: () => void;
  onEdit?: () => void;
  onRename?: () => void;
};

const MenuItem = (props) => (
  <ChakraMenuItem
    fontSize="sm"
    fontWeight={400}
    padding="8px 18px"
    _focus={{
      bg: '#F4F4F4',
    }}
    _active={{
      bg: 'none',
    }}
    {...props}
  />
);

const CardMenu = ({ onDelete, onEdit, onRename }: CardMenuProps) => (
  <Menu placement="bottom">
    <MenuButton
      as={ChakraButton}
      bg="transparent"
      border="none"
      h="15px"
      rightIcon={<IconMenu />}
      minWidth="none"
      onClick={(e) => e.stopPropagation()}
      p={0}
      _hover={{
        border: 'none',
        boxShadow: 'none',
      }}
      _focus={{
        border: 'none',
        boxShadow: 'none',
      }}
      _active={{
        border: 'none',
        boxShadow: 'none',
      }}
    />
    <MenuList
      bg="#FFFFFF"
      border="1px solid"
      borderColor="secondaryGray.300"
      minWidth="none"
      p="0"
      w="124px"
    >
      {onRename ? <MenuItem onClick={onRename}>Rename</MenuItem> : null}
      <MenuItem onClick={onEdit}>Edit</MenuItem>
      <MenuItem onClick={onDelete}>Delete</MenuItem>
    </MenuList>
  </Menu>
);

export default CardMenu;
