import { useState } from 'react';
import {
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  VStack,
  Text,
  useToast,
  HStack,
} from '@chakra-ui/react';

import IconCloseModalSmall from '@/components/icons/IconCloseModal';

import Button from '@/components/button';

type Props = {
  entityName: string;
  onClose: () => void;
  onDelete: () => void;
};

function DeleteModal({ entityName, onClose, onDelete }: Props) {
  const [waiting, setWaiting] = useState(false);
  const toast = useToast();

  const handleDelete = async () => {
    setWaiting(true);
    try {
      await onDelete();
      toast({
        title: `${entityName} deleted`,
        status: 'success',
        duration: 2000,
      });
      onClose();
    } catch (err) {
      let message = `Error deleting ${entityName}`;
      if (err?.message) {
        message += `: ${err.message}`;
      }
      toast({
        title: message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setWaiting(false);
    }
  };

  return (
    <Modal isOpen={true} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent alignSelf="center" borderRadius="10px" w="308px">
        <ModalBody padding="24px">
          <Flex alignItems="center" justifyContent="space-between" w="100%">
            <Box>
              <Text as="b" color="black.700" fontSize="26px" fontWeight={700} mb="12px">
                Delete {entityName}
              </Text>
            </Box>
            <Button bg="transparent" minWidth="none" onClick={onClose} padding={0} tabIndex={-1}>
              <IconCloseModalSmall />
            </Button>
          </Flex>
          <VStack align="flex-start" height="150px" justify="space-between" pt="30px">
            <Text>
              Are you sure? This is permanent and will delete all {entityName} information and data.
            </Text>
            <HStack>
              <Button outlined isLoading={waiting} onClick={onClose}>
                Cancel
              </Button>
              <Button bg="red.400" isLoading={waiting} onClick={handleDelete}>
                Delete {entityName}
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default DeleteModal;
