import { fabric } from 'fabric-spacerunners';

import TemplatePicker from './TemplatePicker';

import { Canvas, CanvasObject, Template } from '@/lib/types';
import { Button, HStack } from '@chakra-ui/react';

const BUTTON_SIZE = 20;
const SELECTED_BORDER_WIDTH = 2;

const BUTTON_SELECTED_SIZE = BUTTON_SIZE + 2 * SELECTED_BORDER_WIDTH;

const isLightColor = (colorName) => ['Blanco', 'White'].includes(colorName);

const ButtonSelectColor = (props) => (
  <Button
    h={`${BUTTON_SIZE}px`}
    flexShrink={0}
    padding="0"
    w={`${BUTTON_SIZE}px`}
    borderRadius="50%"
    minWidth="auto"
    {...props}
  />
);

interface TemplateToolsProps {
  canvas: Canvas;
  isTemplatePickerVisible: boolean;
  onCloseTemplatePicker: () => void;
  isColorPickerVisible: boolean;
  template: Template;
  selectedVariantId: string;
  onSelectedVariant: (variantId: string) => void;
  onObjectAdded: (object: CanvasObject) => void;
}

const TemplateTools = ({
  canvas,
  isTemplatePickerVisible,
  isColorPickerVisible,
  onCloseTemplatePicker,
  template,
  selectedVariantId,
  onSelectedVariant,
  onObjectAdded,
}: TemplateToolsProps) => {
  const { colors } = template;

  const handleSelectedTemplate = (svgAsString: string | null) => {
    if (!svgAsString) {
      onCloseTemplatePicker();

      return;
    }

    const { height, width } = canvas.clipPath || canvas;
    const { left, top } = canvas.clipPath || { left: 0, top: 0 };

    fabric.loadSVGFromString(svgAsString, function (objects, options) {
      const obj = fabric.util.groupSVGElements(objects, options);

      obj
        .scaleToWidth(width / 2)
        .set({
          left: left + width / 2,
          top: top + height / 2,
          originX: 'center',
          originY: 'center',
          centeredScaling: true,
        })
        .setCoords();

      canvas.add(obj).renderAll();

      onObjectAdded(obj);
    });
  };

  return (
    <>
      {isTemplatePickerVisible ? (
        <TemplatePicker onSelectedTemplate={handleSelectedTemplate} />
      ) : null}
      {isColorPickerVisible ? (
        <HStack bg="#FFFFFF" border="1px solid #DDDDDD" borderRadius="43px" p="4px 7px">
          {colors.map(({ id, name, hex }) => {
            const isSelected = selectedVariantId === id;

            return (
              <ButtonSelectColor
                bg={hex}
                border={
                  isSelected
                    ? `${SELECTED_BORDER_WIDTH}px solid #064AC4`
                    : isLightColor(name)
                    ? `${SELECTED_BORDER_WIDTH}px solid #EEEEEE`
                    : 'none'
                }
                h={`${isSelected ? BUTTON_SELECTED_SIZE : BUTTON_SIZE}px`}
                w={`${isSelected ? BUTTON_SELECTED_SIZE : BUTTON_SIZE}px`}
                key={id}
                onClick={(e) => {
                  e.stopPropagation();

                  onSelectedVariant(id);
                }}
              />
            );
          })}
        </HStack>
      ) : null}
    </>
  );
};

export default TemplateTools;
