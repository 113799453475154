import { useState } from 'react';

import { Alert, AlertIcon, Flex, Link, Text, VStack, useToast } from '@chakra-ui/react';

import { GoogleLogin } from '@react-oauth/google';
import { useLocation } from 'react-router-dom';

import { googleLogin, signUp } from '@/api/auth';

import FormInput from '../components/FormInput';
import Button from '../components/ButtonCTA';
import ErrorMessage from '../components/ErrorMessage';
import OrLine from '../components/OrLine';

import { trackEvent } from '../../../analytics';
import { Event } from '../../../analytics/events';

import { handleSignIn } from '../utils';

interface SignUpFormProps {
  onGoToSignIn: () => void;
  onSignedIn: () => void;
}

function SignUpForm({ onGoToSignIn, onSignedIn }: SignUpFormProps) {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const inviteToken = searchParams.get('invite');

  const [error, setError] = useState('');
  const [waiting, setWaiting] = useState(false);
  const [success, setSuccess] = useState(false);

  const toast = useToast();

  const handleError = (error) => {
    const { response } = error;

    const message = response?.data?.message || 'Error signing up';

    setError(message);
  };

  const handleSubmit = () => {
    if (!firstName || !email || !password) {
      setError('Please fill all required fields');

      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords have to match');

      return;
    }

    setError('');
    setWaiting(true);
    setSuccess(false);

    signUp(email, password, firstName, lastName, inviteToken)
      .then(() => {
        setWaiting(false);
        trackEvent(Event.SIGNED_UP, { isFromGoogle: false });

        toast({
          position: 'top',
          title:
            'Registration successful. We have sent you a verification email. Please check your inbox and follow the link in the email to confirm your account.',
          status: 'success',
          duration: null,
          isClosable: true,
        });
      })
      .catch((error) => {
        handleError(error);

        setWaiting(false);
      });
  };

  return (
    <VStack mb="20px" mt="20px" spacing="16px" maxW="350px">
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          googleLogin(credentialResponse.credential, inviteToken)
            .then(({ access_token: accessToken, user }) => {
              handleSignIn(Event.SIGNED_UP, user, accessToken, true);

              onSignedIn();
            })
            .catch(handleError);
        }}
        width="324px"
      />
      <OrLine />
      {success ? (
        <Alert height="60px" status="success">
          <AlertIcon />
          Success! Please login.
        </Alert>
      ) : null}
      <FormInput
        name="First Name"
        placeholder="Enter your first name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
      <FormInput
        name="Last Name"
        placeholder="Enter your last name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />
      <FormInput
        autoComplete="new-password"
        name="Email Address"
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <FormInput
        autoComplete="new-password"
        isPassword
        name="Password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <FormInput
        autoComplete="new-password"
        isPassword
        name="Confirm Password"
        placeholder="Confirm Password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      {error ? <ErrorMessage message={error} /> : null}
      <Button isLoading={waiting} onClick={handleSubmit} w="100%">
        Sign Up
      </Button>
      <Flex align="center" justify="center" w="100%">
        <Text color="#6C757D" fontSize="sm" fontWeight={400}>
          Already have an account?
        </Text>
        <Link color="brand.600" fontSize="sm" fontWeight={400} ml="4px" onClick={onGoToSignIn}>
          Log in
        </Link>
      </Flex>
    </VStack>
  );
}

export default SignUpForm;
