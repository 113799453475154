import Colors from '@/theme/colors';

import CustomArtStyle from '@/views/admin/custom-art-style';
import Dashboard from '@/views/admin/dashboard';
import Home from '@/views/home/HomeSignedIn';
import CollectionPage from '@/views/home/CollectionPage';

import TemplateLibrary from '@/views/admin/template-library';
import DesignCenter from '@/views/admin/design-center';
import Subscription from '@/views/admin/subscription';
import Team from '@/views/admin/team';
import ProfileSettings from '@/views/admin/profile-settings';

import Editor from '@/views/demo/pages/editor';
import RemixSubmittedPage from '@/views/demo/pages/remix-submitted';
import SubmitRemixPage from '@/views/demo/pages/submit-remix';

import TermsOfService from '@/views/legal/TermsOfService';

import IconCustomArtStyle from './icons/IconCustomArtStyle';
import IconDashboard from './icons/IconDashboard';
import IconDemo from './icons/IconDemo';
import IconDesignCenter from './icons/IconDesignCenter';
import IconSubscriptionPlan from './icons/IconSubscriptionPlan';
import IconTeam from './icons/IconTeam';
import IconTemplate from './icons/IconTemplate';
import PrivacyPolicy from '@/views/legal/PrivacyPolicy';

const { abloBlue } = Colors;

const routes = [
  {
    name: 'Home',
    path: '/',
    exact: true,
    icon: <IconDashboard />,
    iconActive: <IconDashboard color={abloBlue} />,
    component: Home,
  },
  {
    name: 'Collection Details',
    path: '/collection/:id',
    notInMenu: true,
    component: CollectionPage,
  },
  {
    name: 'Generate Art',
    defaultPath: '/designs/new',
    path: '/designs/:id',
    icon: <IconDemo />,
    iconActive: <IconDemo color={abloBlue} />,
    component: Editor,
  },
  {
    name: 'Analytics',
    path: '/dashboard',
    icon: <IconDashboard />,
    iconActive: <IconDashboard color={abloBlue} />,
    component: Dashboard,
  },
  {
    name: 'Template library',
    path: '/template-library',
    icon: <IconTemplate />,
    iconActive: <IconTemplate color={abloBlue} />,
    component: TemplateLibrary,
  },
  {
    name: 'Design Center',
    path: '/designs',
    icon: <IconDesignCenter />,
    iconActive: <IconDesignCenter color={abloBlue} />,
    component: DesignCenter,
  },
  {
    name: 'Manage Team',
    path: '/team',
    icon: <IconTeam />,
    iconActive: <IconTeam color={abloBlue} />,
    component: Team,
  },
  {
    name: 'Custom Art style',
    path: '/custom-art-styles',
    icon: <IconCustomArtStyle />,
    iconActive: <IconCustomArtStyle color={abloBlue} />,
    component: CustomArtStyle,
  },
  {
    name: 'Subscription Plan',
    path: '/subscription',
    icon: <IconSubscriptionPlan />,
    iconActive: <IconSubscriptionPlan color={abloBlue} />,
    component: Subscription,
  },
  {
    name: 'Remix submitted',
    path: '/share',
    notInMenu: true,
    component: RemixSubmittedPage,
  },
  {
    name: 'Submit remix',
    path: '/submit',
    notInMenu: true,
    component: SubmitRemixPage,
  },
  {
    name: 'Profile',
    path: '/profile',
    notInMenu: true,
    layout: '',
    component: ProfileSettings,
  },
  {
    name: 'Terms',
    path: '/terms',
    notInMenu: true,
    layout: '',
    component: TermsOfService,
  },
  {
    name: 'Privacy policy',
    path: '/privacy-policy',
    notInMenu: true,
    layout: '',
    component: PrivacyPolicy,
  },
];

export default routes;
