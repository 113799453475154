import { useState } from 'react';

import { useDoubleTap } from 'use-double-tap';

import { Box, ButtonProps, Flex, HStack, Text } from '@chakra-ui/react';

import Button from '../../../components/Button';

import { IconGenerate } from '../../../components/icons/IconGenerate';

import ExpandableImageThumnbail from './ExpandableImageThumbnail';
import ExpandedImage from './ExpandedImage';

import FavoriteButton from './FavoriteButton';

import { AbloImage, Favorite } from '@/lib';

type ImagePreviewProps = {
  images: AbloImage[];
  selectedImage: AbloImage;
  onSelectedImage: (image: AbloImage) => void;
  onPlaceArtwork: () => void;
  onGenerateSimilar: () => void;
  onNewArtwork: () => void;
  buttonProps?: {
    basic: ButtonProps;
    outlined: ButtonProps;
  };
  handleExpand: () => void;
  showGenerateNewButtons?: boolean;
  buttonTitle?: string;
  addFavorite: (favorite: Favorite) => void;
  removeFavorite: (id: string) => void;
  favorites: Favorite[];
};

const ImagesPreview = ({
  images,
  selectedImage,
  onSelectedImage,
  onPlaceArtwork,
  onGenerateSimilar,
  onNewArtwork,
  buttonProps,
  handleExpand,
  showGenerateNewButtons = true,
  buttonTitle = 'Place Artwork',
  addFavorite,
  removeFavorite,
  favorites,
}: ImagePreviewProps) => {
  const [isImageExpanded, setImageExpanded] = useState(false);

  const bindCompressed = useDoubleTap(
    () => {
      onExpand((event.target as HTMLElement).id);
    },
    300,
    {
      onSingleTap: (event) => {
        onSelectedImage(images.find((image) => image.id === (event.target as HTMLElement).id));
      },
    }
  );

  const bindExpanded = useDoubleTap(() => {
    setImageExpanded(false);
  }, 300);

  const onExpand = (imageId: string) => {
    handleExpand();
    onSelectedImage(images.find((image) => image.id === imageId));
    setImageExpanded(true);
  };

  return (
    <Box>
      <Text color="#1A1A1A" fontSize="sm" fontWeight={600} mb="12px">
        Select Image
      </Text>
      {isImageExpanded ? (
        <ExpandedImage
          image={selectedImage}
          bindExpanded={bindExpanded}
          onCompress={() => setImageExpanded(false)}
        />
      ) : (
        <HStack justify="center">
          {images.map((image) => (
            <ExpandableImageThumnbail
              image={image}
              isAutoWidth={images.length === 1}
              isSelected={image.url === selectedImage.url}
              key={image.id}
              onExpand={() => onExpand(image.id)}
              bindCompressed={bindCompressed}
            />
          ))}
        </HStack>
      )}
      <HStack mt="20px">
        <FavoriteButton
          favorites={favorites}
          imageId={selectedImage.id}
          addFavorite={addFavorite}
          removeFavorite={removeFavorite}
        />
        <Button
          onClick={onPlaceArtwork}
          title={buttonTitle}
          w="100%"
          {...(buttonProps?.basic || {})}
          icon={<IconGenerate />}
        />
      </HStack>
      {showGenerateNewButtons && (
        <Flex align="center" mt="14px" pb="14px">
          <Button
            flex={1}
            onClick={onGenerateSimilar}
            variant="outlined"
            title="Generate Similar"
            {...(buttonProps?.outlined || {})}
          />
          <Button
            flex={1}
            ml="10px"
            onClick={onNewArtwork}
            variant="outlined"
            title="New"
            {...(buttonProps?.outlined || {})}
          />
        </Flex>
      )}
    </Box>
  );
};

export default ImagesPreview;
