import { Image } from '@chakra-ui/react';

import { Design } from '@/lib';

const IMAGE_MOBILE_SIZE = 340;

const IMAGE_DESKTOP_SIZE = 440;

interface RemixPreviewImageProps {
  design: Design;
  showMobileSize?: boolean;
}

export default function RemixPreviewImage({ design, showMobileSize }: RemixPreviewImageProps) {
  const { sides = [] } = design || {};

  const { designImage } = sides[0] || {};

  return (
    <Image
      objectFit="contain"
      src={designImage}
      alt="Remix image"
      borderRadius="14px"
      h={{
        base: IMAGE_MOBILE_SIZE,
        lg: showMobileSize ? IMAGE_MOBILE_SIZE : IMAGE_DESKTOP_SIZE,
      }}
      w={{
        base: IMAGE_MOBILE_SIZE,
        lg: showMobileSize ? IMAGE_MOBILE_SIZE : IMAGE_DESKTOP_SIZE,
      }}
    />
  );
}
