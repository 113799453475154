import { Icon } from '@chakra-ui/react';

const IconShare = ({ color = null, ...rest }) => (
  <Icon
    width="25px"
    height="25px"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M21.957 11.3632L13.957 3.36323C13.8172 3.22337 13.639 3.12813 13.445 3.08954C13.2511 3.05094 13.05 3.07074 12.8673 3.14641C12.6846 3.22209 12.5284 3.35025 12.4185 3.51469C12.3086 3.67913 12.25 3.87246 12.25 4.07023V7.61515C9.5183 7.868 6.97926 9.13109 5.12973 11.1573C3.2802 13.1834 2.25331 15.8269 2.25 18.5702V20.0702C2.24998 20.2779 2.31463 20.4804 2.43495 20.6497C2.55528 20.819 2.72532 20.9466 2.92147 21.0148C3.11762 21.083 3.33014 21.0885 3.52954 21.0304C3.72893 20.9724 3.90531 20.8537 4.03418 20.6908C5.01383 19.5258 6.21583 18.5676 7.56997 17.8724C8.9241 17.1771 10.4032 16.7587 11.9209 16.6415C11.9707 16.6352 12.0957 16.6254 12.25 16.6156V20.0702C12.25 20.268 12.3086 20.4613 12.4185 20.6258C12.5284 20.7902 12.6846 20.9184 12.8673 20.994C13.05 21.0697 13.2511 21.0895 13.445 21.0509C13.639 21.0123 13.8172 20.9171 13.957 20.7772L21.957 12.7772C22.0499 12.6844 22.1235 12.5742 22.1738 12.4529C22.2241 12.3316 22.2499 12.2015 22.2499 12.0702C22.2499 11.9389 22.2241 11.8089 22.1738 11.6876C22.1235 11.5663 22.0499 11.4561 21.957 11.3632ZM14.25 17.6562V15.5702C14.25 15.4389 14.2242 15.3088 14.1739 15.1875C14.1237 15.0662 14.05 14.9559 13.9572 14.863C13.8643 14.7702 13.7541 14.6965 13.6327 14.6463C13.5114 14.596 13.3813 14.5702 13.25 14.5702C12.9951 14.5702 11.9541 14.6195 11.6885 14.6552C8.99307 14.9035 6.42736 15.9275 4.30176 17.6034C4.54289 15.3978 5.58918 13.3585 7.24026 11.8763C8.89135 10.394 11.0312 9.57294 13.25 9.57023C13.3813 9.57026 13.5114 9.54442 13.6327 9.49418C13.7541 9.44393 13.8643 9.37028 13.9572 9.27741C14.05 9.18454 14.1237 9.07429 14.1739 8.95295C14.2242 8.83161 14.25 8.70156 14.25 8.57023V6.48429L19.8359 12.0702L14.25 17.6562Z"
      fill={color || '#064AC4'}
    />
  </Icon>
);

export default IconShare;
