import {
  FormControl,
  FormErrorMessage,
  Input as ChakraInput,
  NumberInput as ChakraNumberInput,
  NumberInputField,
  Textarea as ChakraTextArea,
  InputGroup,
  InputRightElement,
  Button,
} from '@chakra-ui/react';

import FormLabel from './FormLabel';
import IconEyeClosed from '../icons/IconEyeClosed';
import IconEye from '../icons/IconEye';
import { useState } from 'react';

const BORDER_COLOR = 'secondaryGray.500';

const InputStyleProps = {
  padding: '17px 24px',
  bg: '#FFFFFF',
  border: '1px solid',
  borderColor: BORDER_COLOR,
  borderRadius: '16px',
  color: 'black.600',
  fontSize: 'sm',
  fontWeight: 400,
  h: '50px',
  _placeholder: {
    color: 'secondaryGray.600',
  },
};

const PasswordInput = (props) => {
  const [show, setShow] = useState(false);

  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md">
      <Input
        placeholder="Enter password"
        {...InputStyleProps}
        {...props}
        type={show ? 'text' : 'password'}
      />
      <InputRightElement top="5px">
        <Button
          bg="transparent"
          h="100%"
          minW="none"
          size="sm"
          onClick={handleClick}
          w="20px"
          tabIndex={-1}
        >
          {show ? <IconEyeClosed /> : <IconEye />}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};

const NumberInput = (props) => (
  <ChakraNumberInput {...props}>
    <NumberInputField
      {...InputStyleProps}
      _hover={{ borderColor: BORDER_COLOR }}
      _focus={{ borderColor: BORDER_COLOR, boxShadow: 'none' }}
    />
  </ChakraNumberInput>
);

const Textarea = (props) => <ChakraTextArea {...InputStyleProps} variant="auth" {...props} />;

const Input = (props) => <ChakraInput {...InputStyleProps} variant="auth" {...props} />;

function FormInput(props) {
  const { error, isNumber, isPassword, isTextArea, isOptional, name, ...rest } = props;

  return (
    <FormControl isInvalid={error} w="100%">
      {name ? <FormLabel isOptional={isOptional}>{name}</FormLabel> : null}
      {(isTextArea && <Textarea {...rest} />) ||
        (isNumber && <NumberInput {...rest} />) ||
        (isPassword && <PasswordInput {...rest} />) || <Input {...rest} />}
      {error ? <FormErrorMessage>{error}</FormErrorMessage> : null}
    </FormControl>
  );
}

export default FormInput;
