import { Icon } from '@chakra-ui/react';

const IconSubscriptionPlan = ({ color }: { color?: string }) => (
  <Icon
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19 11H13V5H11V11H5V13H11V19H13V13H19V11Z" fill={color || '#A3AED0'} />
  </Icon>
);

export default IconSubscriptionPlan;
