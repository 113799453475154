import { Box, HStack, Text, Tooltip, VStack } from '@chakra-ui/react';
import { LedgerPeriod } from '../../../../../api/ledger';

interface ICreditUsageToolTipProps {
  children: React.ReactNode;
  ledgerPeriod: LedgerPeriod;
  month: string;
  billableActionNames: string[];
  colors: string[];
}

const DOT_DIAMETER = 6;

export const CreditUsageToolTip: React.FC<ICreditUsageToolTipProps> = ({
  children,
  ledgerPeriod,
  month,
  colors,
  billableActionNames,
}: ICreditUsageToolTipProps) => {
  const totalUsage = billableActionNames.reduce(
    (sum, action) => sum + (ledgerPeriod.debitsByBillableAction[action] || 0),
    0
  );

  const tooltip = (
    <Box borderRadius={'16px'} bg="white" p={4} color="black">
      <Text fontSize="lg" fontWeight="bold" mb={3}>
        {month}
      </Text>
      <VStack spacing={2} align="stretch">
        {billableActionNames.map((action, index) => (
          <HStack key={index} justifyContent="space-between">
            <HStack>
              <Box
                h={`${DOT_DIAMETER}px`}
                w={`${DOT_DIAMETER}px`}
                bg={colors[index]}
                borderRadius="full"
              />
              <Text fontSize="sm">{action}</Text>
            </HStack>
            <Text fontSize="sm" textAlign="right">
              {ledgerPeriod.debitsByBillableAction[action] || 0}
            </Text>
          </HStack>
        ))}
        <HStack justifyContent="space-between">
          <Text fontSize="md" fontWeight="semibold">
            Total Usage
          </Text>
          <Text fontSize="md" fontWeight="semibold">
            {totalUsage}
          </Text>
        </HStack>
      </VStack>
    </Box>
  );

  return (
    <Tooltip label={tooltip} backgroundColor={'white'}>
      {children}
    </Tooltip>
  );
};
