import { Icon } from '@chakra-ui/react';

const IconVoteDisabled = ({ color = null }) => (
  <Icon
    width="21px"
    height="21px"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6851 11.4689L11.1323 2.2605L2.57959 11.4689H7.23929L7.23929 18.8089L15.1756 18.8089V11.4689L19.6851 11.4689Z"
      fill={color || 'black'}
    />
  </Icon>
);

export default IconVoteDisabled;
