import {
  HStack,
  Modal,
  ModalContent,
  ModalBody,
  Text,
  Spacer,
  useToast,
  Box,
  Spinner,
  Center,
  ModalOverlay,
} from '@chakra-ui/react';

import Button from '../../buttons/ButtonCTA';
import Table from '../../../../../../components/table';
import { useMe } from '../../../../../../api/auth';
import {
  useDeleteApiKey,
  useGenerateApiKey,
  useUpdateApiKey,
} from '../../../../../../api/api-keys';
import { ApiKeyMenu } from './ApiKeyMenu';

import { StorageKeys } from '@/constants';

import { useState } from 'react';

import ApiIntegationConfirmationModal from './ApiIntegrationConfirmationModal';
import { IconCopy } from '@/components/icons/IconCopy';

const { HAS_CONFIRMED_INTEGRATION } = StorageKeys;

type Props = {
  onClose: () => void;
};

export const ApiKeysModal = ({ onClose }: Props) => {
  const [pendingTextToClipboard, setPendingTextToClipboard] = useState<string>(null);
  const [isConfirmationModalVisible, setConfirmationModalVisible] = useState(false);

  const { data: me } = useMe();
  const { generateApiKey } = useGenerateApiKey();
  const { updateApiKey } = useUpdateApiKey();
  const { deleteApiKey } = useDeleteApiKey();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  // Should not allow deleting or deactivating the original key since
  // the dashboard will not work without it
  const originalApiKey = me.client.apiKeys.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))[0];

  const handleGenerate = async () => {
    if (!localStorage.getItem(HAS_CONFIRMED_INTEGRATION)) {
      setConfirmationModalVisible(true);

      return;
    }

    sendKeyGenerationRequest();
  };

  const sendKeyGenerationRequest = async () => {
    setIsLoading(true);
    try {
      await generateApiKey();
    } catch (error) {
      toast({
        title: 'Error generating API key',
        description: `${error}`,
        status: 'error',
        position: 'top',
      });
    }
    setIsLoading(false);
  };

  const handleConfirmation = () => {
    localStorage.setItem(HAS_CONFIRMED_INTEGRATION, 'true');

    if (pendingTextToClipboard) {
      copyToClipboard(pendingTextToClipboard);

      setPendingTextToClipboard(null);
      setConfirmationModalVisible(false);

      return;
    }

    setConfirmationModalVisible(false);

    sendKeyGenerationRequest();
  };

  const handleDeativate = async (id: string) => {
    if (id === originalApiKey.id) {
      toast({
        title: 'Cannot deactivate original API key',
        status: 'error',
        position: 'top',
      });
    } else {
      setIsLoading(true);
      await updateApiKey({ id, isActive: false });
      setIsLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    if (id === originalApiKey.id) {
      toast({
        title: 'Cannot delete original API key',
        status: 'error',
        position: 'top',
      });
    } else {
      setIsLoading(true);
      await deleteApiKey(id);
      setIsLoading(false);
    }
  };

  const handleActivate = async (id: string) => {
    setIsLoading(true);
    await updateApiKey({ id, isActive: true });
    setIsLoading(false);
  };

  const handleCopyToClipboard = (text: string) => {
    if (!localStorage.getItem(HAS_CONFIRMED_INTEGRATION)) {
      setConfirmationModalVisible(true);

      setPendingTextToClipboard(text);

      return;
    }

    copyToClipboard(text);
  };

  const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);

    toast({ title: 'Copied to clipboard', status: 'success', position: 'top' });
  };

  const COLUMNS = [
    {
      name: 'Key',
      property: 'key',
      customContent: (row) => (
        <HStack>
          <Text color="black.700" fontSize="sm" fontWeight={700} lineHeight="24px">
            {localStorage.getItem(HAS_CONFIRMED_INTEGRATION)
              ? row.key
              : `${row.key.slice(0, 10)}...`}
          </Text>
          <>
            <Spacer />
            <Box pr="16px" onClick={() => handleCopyToClipboard(row.key)}>
              <IconCopy />
            </Box>
          </>
        </HStack>
      ),
    },
    {
      name: 'Status',
      property: 'status',
    },
    {
      name: 'Created On',
      property: 'createdAt',
    },
    {
      name: 'Last Used',
      property: 'lastUsedAt',
    },
    {
      name: '# of Calls',
      property: 'numCalls',
    },
    {
      name: 'Action',
      getActionComponent: (row) => (
        <ApiKeyMenu
          isActive={row.status === 'Active'}
          onDeactivate={() => handleDeativate(row.id)}
          onDelete={() => handleDelete(row.id)}
          onActivate={() => handleActivate(row.id)}
        />
      ),
    },
  ];

  const rows = me.client.apiKeys
    .filter((apiKey) => !apiKey.isDeleted)
    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    .map((apiKey) => ({
      id: apiKey.id,
      key: apiKey.key,
      status: apiKey.isActive ? 'Active' : 'Inactive',
      createdAt: apiKey.createdAt.toString().split('T')[0],
      lastUsedAt: apiKey.lastUsedAt?.toString().split('T')[0],
      numCalls: apiKey.numCalls,
      actions: '...',
    }));

  return isConfirmationModalVisible ? (
    <ApiIntegationConfirmationModal
      onConfirm={handleConfirmation}
      onClose={() => setConfirmationModalVisible(false)}
    />
  ) : (
    <Modal isOpen={true} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent alignSelf="center" borderRadius="20px" maxWidth="80%">
        <ModalBody padding="19px 31px">
          <HStack mb="2">
            <Text color="black.600" fontSize="24px" fontWeight="700">
              API Keys
            </Text>
            <Spacer />
            <Button onClick={handleGenerate} p="12px">
              Generate API Key
            </Button>
          </HStack>

          <Box position="relative">
            <Table columns={COLUMNS} rows={rows} />
            {isLoading && (
              <Center
                position="absolute"
                top="0"
                right="0"
                bottom="0"
                left="0"
                bg="whiteAlpha.600" // background to slightly obscure the table and highlight the spinner
              >
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="gray"
                  color="brand.500"
                  size="lg"
                />
              </Center>
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
