import {
  Box,
  Card,
  CardBody,
  Center,
  Flex,
  Image,
  Spinner,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';

import { Design } from '@/lib/types';

import { deleteDesign, updateBaseDesign } from '@/api/designs';

import { timeAgo } from '@/utils/time';

import CardMenu from '../components/CardMenu';
import { useHistory } from 'react-router-dom';

import RenameDesignModal from './RenameDesignModal';
import { useEffect, useState } from 'react';
import { getDesignPreviewImage } from '@/lib';

const THUMBNAIL_SIZE = 207;

type DesignCardProps = {
  design: Design;
  onDesignDeleted: (id: string) => void;
  onDesignUpdated: (updates: Partial<Design>) => void;
  onDesignSelected: (design: Design) => void;
};

const DesignCard = ({
  design,
  onDesignDeleted,
  onDesignUpdated,
  onDesignSelected,
}: DesignCardProps) => {
  const [renamingDesign, setRenamingDesign] = useState<Design>(null);
  const [isUpdating, setUpdating] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const [isLoadingPreviewImage, setIsLoadingPreviewImage] = useState(true);
  const [designPreviewImage, setDesignPreviewImage] = useState<string>(null);

  const history = useHistory();

  const toast = useToast();

  useEffect(() => {
    getDesignPreviewImage(design).then(({ designImage }) => {
      setDesignPreviewImage(designImage);

      setIsLoadingPreviewImage(false);
    });
  }, []);

  const { id, name } = design;

  const handleRename = (name) => {
    setUpdating(true);

    const updates = { id: renamingDesign.id, name };

    updateBaseDesign(updates).then(() => {
      setUpdating(false);

      setRenamingDesign(null);

      onDesignUpdated(updates);
    });
  };

  const handleDelete = () => {
    setDeleting(true);

    deleteDesign(id)
      .then(() => {
        onDesignDeleted(id);

        toast({
          position: 'top',
          title: 'Design successfully deleted',
          status: 'success',
          duration: 1000,
        });
      })
      .catch((e) => {
        setDeleting(false);

        toast({
          position: 'top',
          title: 'Error deleting design',
          description: e.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      });
  };

  const handleEdit = (id: string) => history.push(`/designs/${id}`);

  return (
    <Box>
      <Card
        h="356px"
        w="248px"
        bgColor="#FFFFFF"
        borderRadius={20}
        alignSelf="stretch"
        key={id}
        position="relative"
      >
        <CardBody p="36px 17px 22px 24px">
          <Center
            bg="#F7FAFC"
            borderRadius="18px"
            cursor="pointer"
            h={`${THUMBNAIL_SIZE}px`}
            onClick={() => onDesignSelected(design)}
            position="relative"
            p="25px 18px"
          >
            {isLoadingPreviewImage ? null : (
              <Image maxH="100%" maxW="100%" objectFit="contain" src={designPreviewImage} />
            )}
            {isDeleting || isLoadingPreviewImage ? (
              <Center
                position="absolute"
                w="100%"
                h="100%"
                bg={isLoadingPreviewImage ? 'transparent' : 'black'}
                opacity="0.5"
                top={0}
              >
                <Spinner
                  thickness="1px"
                  speed="0.65s"
                  emptyColor="gray"
                  color="brand.500"
                  size="md"
                />
              </Center>
            ) : null}
          </Center>
          <VStack align="left" pt="20px" spacing="20px">
            <Flex justify="space-between" position="relative">
              <Text color="secondaryDarkGray.900" fontSize="15px" fontWeight={700}>
                {name}
              </Text>
              <CardMenu
                onDelete={handleDelete}
                onEdit={() => handleEdit(id)}
                onRename={() => setRenamingDesign(design)}
              />
            </Flex>
            <Text color="#6A6866" fontSize="11px">
              Edited {timeAgo(design.updatedAt)}
            </Text>
          </VStack>
        </CardBody>
      </Card>
      {renamingDesign && (
        <RenameDesignModal
          designName={renamingDesign.name}
          isRenaming={isUpdating}
          onClose={() => setRenamingDesign(null)}
          onSave={handleRename}
        />
      )}
    </Box>
  );
};

export default DesignCard;
