import { Icon } from '@chakra-ui/react';

const IconTrash = (props) => (
  <Icon
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1817_26146)">
      <path
        d="M5.33301 9.33301H26.6663"
        stroke="black"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.333 14.6665V22.6665"
        stroke="black"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.666 14.6665V22.6665"
        stroke="black"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66602 9.33301L7.99935 25.333C7.99935 26.0403 8.2803 26.7185 8.7804 27.2186C9.28049 27.7187 9.95877 27.9997 10.666 27.9997H21.3327C22.0399 27.9997 22.7182 27.7187 23.2183 27.2186C23.7184 26.7185 23.9994 26.0403 23.9994 25.333L25.3327 9.33301"
        stroke="black"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 9.33333V5.33333C12 4.97971 12.1405 4.64057 12.3905 4.39052C12.6406 4.14048 12.9797 4 13.3333 4H18.6667C19.0203 4 19.3594 4.14048 19.6095 4.39052C19.8595 4.64057 20 4.97971 20 5.33333V9.33333"
        stroke="black"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1817_26146">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

export default IconTrash;
