import { DESIGN_TEMPLATE_REFERENCE_WIDTH } from '@/constants';
import '@/lib/editor/fabric';

import { Canvas, CanvasObject, Template, TemplateColorImage, TemplateSide } from '../types';
import { fabric } from 'fabric-spacerunners';

const loadCanvasState = (canvas, stateAsJson) =>
  new Promise((resolve) => {
    canvas.loadFromJSON(stateAsJson, () => {
      canvas.renderAll();

      resolve(null);
    });
  });

const getObjectCopy = (object: CanvasObject) =>
  new Promise<CanvasObject>((resolve) => {
    object.clone((cloned) => resolve(cloned));
  });

const loadImage = (url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve) => {
    const img = new Image();

    img.crossOrigin = 'anonymous';
    img.onload = () => resolve(img);
    img.onerror = () => resolve(null);
    img.src = url;
  });
};

export const getPreviewImage = (
  designOutputImageAsBase64: string,
  template: Template,
  templateSide: TemplateSide,
  templateColorId: string
): Promise<string> => {
  const color = template.colors.find(({ id }) => id === templateColorId) || template.colors[0];

  const { images } = color;

  const { id, left, top, height, width } = templateSide || {};

  const templateImage =
    (images as TemplateColorImage[]).find(({ templateSideId }) => id === templateSideId) ||
    images[0];

  if (!templateImage) {
    return Promise.resolve(null);
  }

  if (!designOutputImageAsBase64) {
    return Promise.resolve(templateImage.url);
  }

  const imagesToLoad = [templateImage.url, designOutputImageAsBase64];

  return Promise.all(imagesToLoad.map((src) => loadImage(src))).then(
    ([templateImage, designImage]) => {
      const helperCanvas = document.createElement('canvas');

      const upscaleFactor = 2;

      helperCanvas.width = DESIGN_TEMPLATE_REFERENCE_WIDTH * upscaleFactor;
      helperCanvas.height = templateImage
        ? DESIGN_TEMPLATE_REFERENCE_WIDTH *
          (templateImage.height / templateImage.width) *
          upscaleFactor
        : helperCanvas.width;

      const context = helperCanvas.getContext('2d');

      if (templateImage) {
        context.drawImage(templateImage, 0, 0, helperCanvas.width, helperCanvas.height);
      }

      if (designImage) {
        context.drawImage(
          designImage,
          left * upscaleFactor,
          top * upscaleFactor,
          width * upscaleFactor,
          height * upscaleFactor
        );
      }

      const previewImage = helperCanvas.toDataURL();

      return previewImage;
    }
  );
};

export const getHighResolutionDesignOutputImage = (
  canvas: Canvas,
  side: TemplateSide
): Promise<string> => {
  const { id, manufacturingImageHeight, manufacturingImageWidth } = side;

  const { clipPath } = canvas;

  const { left: offsetLeft, top: offsetTop } = clipPath;

  const { width } = clipPath || canvas;

  const upscaleFactor = manufacturingImageWidth / width;

  const outputCanvas = new fabric.Canvas(`canvas-high-res-output-${id}`, {
    controlsAboveOverlay: true,
    width: manufacturingImageWidth,
    height: manufacturingImageHeight,
  });

  return Promise.all(
    canvas._objects.map((object) =>
      getObjectCopy(object).then((copy) => {
        const { left, scaleX, scaleY, top } = copy;

        copy.left = upscaleFactor * (left - offsetLeft);
        copy.top = upscaleFactor * (top - offsetTop);
        copy.scaleX = scaleX * upscaleFactor;
        copy.scaleY = scaleY * upscaleFactor;

        return copy;
      })
    )
  ).then((objects) => {
    const stateAsJson = JSON.stringify({ objects });

    return loadCanvasState(outputCanvas, stateAsJson).then(() => {
      const result = outputCanvas.toDataURL({
        width: outputCanvas.width,
        height: outputCanvas.height,
        left: 0,
        top: 0,
        format: 'png',
      });

      return result;
    });
  });
};

const toggleNonExportableObjectsVisibility = (canvas, isVisible) => {
  canvas._objects.forEach((object) => {
    if (object.excludeFromExport) {
      object.visible = isVisible;
    }
  });
};

export const getDesignOutputImage = (canvas, scalingFactor = 1) => {
  const { clipPath } = canvas;

  const { left = 0, top = 0, width, height } = clipPath || {};

  toggleNonExportableObjectsVisibility(canvas, false);

  canvas.backgroundImage = null;
  canvas.overlayImage = null;

  const result = canvas.toDataURL({
    width,
    height,
    multiplier: scalingFactor,
    left,
    top,
    format: 'png',
  });

  toggleNonExportableObjectsVisibility(canvas, true);

  return result;
};

export const getCanvas = (templateSide: TemplateSide) => {
  const {
    width: cssWidth,
    height: cssHeight,
    id,
    manufacturingImageWidth,
    manufacturingImageHeight,
  } = templateSide;

  const upscaledWidth = cssWidth * 3;
  const upscaledHeight = cssHeight * 3;

  const width = manufacturingImageWidth || upscaledWidth;
  const height = manufacturingImageHeight || upscaledHeight;

  const canvasId = `canvas-${id}`;

  const helperCanvas = document.createElement('canvas');
  helperCanvas.setAttribute('id', canvasId);

  return new fabric.Canvas(canvasId, {
    width,
    height,
    enableRetinaScaling: false,
  });
};

export const getDesignOutputImageFromJson = (canvas, canvasStateAsJson): Promise<string> =>
  new Promise((resolve) => {
    if (!canvasStateAsJson) {
      const designOutputImage = getDesignOutputImage(canvas);

      resolve(designOutputImage);
    }

    try {
      canvas.loadFromJSON(canvasStateAsJson, () => {
        canvas.renderAll();

        const designOutputImage = getDesignOutputImage(canvas);

        resolve(designOutputImage);

        canvas.dispose();
      });
    } catch (e) {
      resolve(null);
    }
  });
