import { useState } from 'react';

import { Center, HStack, Spinner, VStack } from '@chakra-ui/react';

import { useUsers, useUserRoles, useInvites, useDeleteUser, useDeleteInvite } from '@/api/users';

import { Invite, User } from '@/components/types';

import InputSearch from '@/components/input-search';
import Table, { TableColumn } from '@/components/table';

import UpdateUserModal from './UpdateUserModal';
import DeleteModal from './DeleteModal';
import { useMe } from '../../../api/auth';
import Button from '../../../components/button';
import InviteModal from './InviteModal';
import AppContainer from '@/layouts/AppContainer';

const REGEX_WHITESPACE: RegExp = /\s+/g;

const filterItems = <T,>(items: T[], fields: string[], term: string) => {
  if (!term || !items || !items.length || !fields || !fields.length) {
    return items;
  }
  const termClean = term.trim().replace(REGEX_WHITESPACE, ' ').toLowerCase();
  const words = termClean.split(' ');
  return items.filter((item) => {
    let matches = true;
    // All words must be present in any fields
    for (const word of words) {
      let wordMatches = false;
      for (const field of fields) {
        if (item[field] && item[field].toLowerCase().includes(word)) {
          wordMatches = true;
        }
        if (wordMatches) break;
      }
      if (!wordMatches) {
        matches = false;
        break;
      }
    }
    return matches;
  });
};

const COLUMNS = [
  {
    name: 'Email Address',
    property: 'email',
  },
  {
    name: 'Status',
    property: 'status',
  },
  {
    name: 'Invited By',
    property: 'invitedByName',
  },
];

const COLUMNS_USERS = [
  {
    name: 'Name',
    property: 'name',
  },
  {
    name: 'Type',
    property: 'role',
  },
  ...COLUMNS,
];

const getUserRows = (users: User[]): User[] =>
  users.map((user) => {
    const { firstName, lastName, email, roles = [] } = user;

    const roleNames = roles.map(({ name }) => name);

    let role = 'Member';

    if (roleNames.includes('super_admin')) {
      role = 'Super admin';
    } else if (roleNames.includes('admin')) {
      role = 'Admin';
    }

    const invitedByName = user.invitedBy
      ? `Invited by ${user.invitedBy.firstName} ${user.invitedBy.lastName}`
      : 'Joined independently';

    return {
      ...user,
      email,
      invitedByName,
      name: `${firstName} ${lastName}`,
      role,
      status: 'Active',
    };
  });

const getInviteRows = (invites: Invite[]): Invite[] =>
  invites.map((invite) => {
    const {
      email,
      invitedBy: { firstName, lastName },
    } = invite;
    const invitedByName = `Invited by ${firstName} ${lastName}`;

    return {
      ...invite,
      email,
      invitedByName,
      name: '-',
      role: '-',
      status: 'Pending',
    };
  });

export default function Team() {
  const [search, setSearch] = useState('');
  const [editingUser, setEditingUser] = useState<User>(null);
  const [userToDelete, setUserToDelete] = useState<User>(null);
  const [inviteToDelete, setInviteToDelete] = useState<Invite>(null);
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);

  const { data: me } = useMe();
  const isAdmin = me.roles.find((r) => ['admin', 'super_admin'].includes(r.name));
  const { data: users, isLoading: isLoadingUsers } = useUsers();
  const { data: invites, isLoading: isLoadingInvites } = useInvites();
  const { removeUser } = useDeleteUser();
  const { removeInvite } = useDeleteInvite();

  const isLoading = isLoadingUsers && isLoadingInvites;

  const { data: roles = [] } = useUserRoles();

  const userSearchFields = ['email', 'firstName', 'lastName'];
  const usersMatchingFilters = filterItems<User>(users, userSearchFields, search) || [];
  const userRows = getUserRows(usersMatchingFilters);

  const inviteSearchFields = ['email'];
  const invitesMatchingFilters = filterItems<Invite>(invites, inviteSearchFields, search) || [];
  const inviteRows = getInviteRows(invitesMatchingFilters);

  const columnsUsers: TableColumn<User>[] = [...COLUMNS_USERS];
  const columnsInvites: TableColumn<Invite>[] = [...COLUMNS];
  if (isAdmin) {
    const columnAction = { name: 'Action' };
    columnsUsers.push(columnAction);
    columnsInvites.push(columnAction);
  }

  const handleEditUser = (user: User) => setEditingUser(user);
  const handleDeleteUser = (user: User) => setUserToDelete(user);
  const handleInvite = () => setShowInviteModal(true);
  const handleDeleteInvite = (invite: Invite) => setInviteToDelete(invite);

  return (
    <AppContainer
      rightSideNavbarContent={
        <HStack>
          <InputSearch search={search} onSearchChange={setSearch} />{' '}
          {isAdmin && (
            <Button onClick={handleInvite} w="164px">
              + Add Member
            </Button>
          )}
        </HStack>
      }
    >
      {isLoading ? (
        <Center bg="transparent" h="calc(100vh - 100px)">
          <Spinner thickness="1px" speed="0.65s" emptyColor="gray" color="brand.500" size="md" />
        </Center>
      ) : (
        <VStack>
          <Table
            columns={columnsUsers}
            onDeleteItem={handleDeleteUser}
            onEditItem={handleEditUser}
            rows={userRows}
            title="Members"
          />
          <Table
            columns={columnsInvites}
            rows={inviteRows}
            title="Invites"
            onDeleteItem={handleDeleteInvite}
          />
        </VStack>
      )}
      {editingUser ? (
        <UpdateUserModal onClose={() => setEditingUser(null)} roles={roles} user={editingUser} />
      ) : null}
      {userToDelete ? (
        <DeleteModal
          entityName="Member"
          onDelete={() => removeUser(userToDelete.id)}
          onClose={() => setUserToDelete(null)}
        />
      ) : null}
      {inviteToDelete ? (
        <DeleteModal
          entityName="Invite"
          onDelete={() => removeInvite(inviteToDelete.id)}
          onClose={() => setInviteToDelete(null)}
        />
      ) : null}
      {showInviteModal ? <InviteModal onClose={() => setShowInviteModal(false)} /> : null}
    </AppContainer>
  );
}
