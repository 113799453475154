import { Icon } from '@chakra-ui/react';

const IconImageToImage = () => (
  <Icon
    width="29px"
    height="28px"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.0769 5.17993H12.9769V18.9559H15.0769V5.17993Z" fill="black" />
    <path
      d="M19.095 11.186L14.125 6.21605L9.15499 11.186L7.67099 9.70205L14.125 3.24805L20.579 9.70205L19.095 11.186Z"
      fill="black"
    />
    <path
      d="M23.7709 24.8499H4.47894V16.4919H6.57894V22.7499H21.6709V16.4919H23.7709V24.8499Z"
      fill="black"
    />
  </Icon>
);

export default IconImageToImage;
