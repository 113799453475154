import {
  Box,
  HStack,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';

import type { Stripe } from 'stripe';

import { usePlans, sendCustomPlanInquiry } from '@/api/subscription';

import ButtonClose from '../components/modals/ButtonCloseModal';

import ButtonSelect from '../components/buttons/ButtonSelect';

import PlanCard from './components/PlanCard';
import LetsChatImage from './components/assets/LetsChat.png';
import { FREE_PLAN_ID } from './constants';
import { trackEvent } from '../../../../analytics';
import { Event } from '../../../../analytics/events';

type Props = {
  onClose: () => void;
  onSelectedPlan: (plan: Stripe.Plan | null) => void;
  onCustomPlanInquirySent: () => void;
  subscription: Stripe.Subscription;
};

const PlansModal = ({ onClose, onSelectedPlan, onCustomPlanInquirySent }: Props) => {
  const { data: plans = [], isLoading } = usePlans();

  const toast = useToast();

  const handleSelectEnterprisePlan = () => {
    trackEvent(Event.CONTACT_US_SELECTED);

    sendCustomPlanInquiry()
      .then(onCustomPlanInquirySent)
      .catch((error) =>
        toast({
          title: 'Error sending inquiry',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      );
  };

  return (
    <Modal isOpen={true} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent alignSelf="center" borderRadius="10px" maxH="fit-content" maxW="fit-content">
        <ModalBody padding="150px 100px 100px" position="relative">
          <Box
            bg="linear-gradient(105deg, #CEEDFF 16.17%, rgba(255, 255, 255, 0.00) 87.53%)"
            position="absolute"
            h="363px"
            w="100%"
            left={0}
            top={0}
          />
          <VStack justify="flex-start" mb="40px" position="relative" spacing={0} w="100%">
            <Text as="b" color="black.600" fontSize="32px">
              Plan Selection
            </Text>
          </VStack>
          <Box position="absolute" right="40px" top="40px">
            <ButtonClose onClose={onClose} />
          </Box>
          {!isLoading ? (
            <HStack spacing="26px">
              {plans.map((plan, index) => {
                const { id, product, amount, currency, interval } = plan;

                const { features, name } = product as Stripe.Product;

                const isStarter = id === FREE_PLAN_ID;

                return (
                  <PlanCard
                    amount={amount}
                    currency={currency}
                    features={features.map(({ name }) => name)}
                    interval={interval}
                    name={name}
                    dark={index === 2}
                    key={index}
                    onClick={() => {
                      onSelectedPlan(isStarter ? null : (plan as Stripe.Plan));
                      trackEvent(Event.PLAN_SELECTED + `: ${name}`);
                    }}
                    button={<ButtonSelect outlined>Select</ButtonSelect>}
                  />
                );
              })}
              <PlanCard
                name="Custom/Enterprise Plan"
                subtitle="Contact Us"
                onClick={handleSelectEnterprisePlan}
                button={<ButtonSelect outlined>Schedule a call</ButtonSelect>}
              >
                <Image margin="0 auto" src={LetsChatImage} w="115px" />
              </PlanCard>
            </HStack>
          ) : null}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PlansModal;
