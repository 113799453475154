import { Fragment as F, useEffect, useRef } from 'react';

import { Box, useBreakpointValue } from '@chakra-ui/react';

import times from 'lodash/times';

import { Canvas, Template } from '../../types';

import { renderRotateLabel } from '../fabric';

import { getDrawingArea } from '../utils/drawingAreas';

import HintPopover from './hints/HintPopover';
import { getCanvasHintProps, Hint } from './hints/hints';

type Props = {
  activeHint: Hint;
  onNextHint: (hint: Hint) => void;
  canvas: Canvas;
  template: Template;
  side: string;
  showHint: boolean;
  onHintClick: () => void;
};

const CanvasContainer = ({ activeHint, onNextHint, canvas, side, template }: Props) => {
  const userState = useRef({ angle: 0, isModifying: false, isRotating: false });

  const isMobile = useBreakpointValue({ base: true, md: false });

  const drawingArea = getDrawingArea(template, side, isMobile);

  useEffect(() => {
    if (!canvas) {
      return;
    }

    canvas.on('mouse:up', function (e) {
      const { isRotating } = userState.current;

      if (isRotating) {
        const newAngle = Math.round(e.target.angle);

        const commonAngles = times(9, (index) => index * 45);

        const nearestAngle = commonAngles.find((angle) => Math.abs(newAngle - angle) <= 5);

        e.target.set('angle', nearestAngle !== undefined ? nearestAngle : newAngle);
      }

      userState.current.isRotating = false;
    });

    canvas.on('mouse:down', function (e) {
      if (e.target) {
        userState.current.isModifying = true;
      }
    });

    canvas.on('object:rotating', function (e) {
      userState.current.isRotating = true;
      userState.current.angle = e.target.angle;
    });

    canvas.on('after:render', function (opt) {
      userState.current.isRotating && renderRotateLabel(opt.ctx, userState.current);
    });
  }, [canvas]);

  const hintProps = getCanvasHintProps(activeHint, isMobile);

  const { clipPath } = canvas || {};

  return (
    <F>
      <HintPopover
        isOpen={isMobile && activeHint === Hint.PINCH_ZOOM}
        onNext={() => onNextHint(Hint.FINISH_AND_SHARE)}
        offset={[0, 200]}
        {...hintProps}
      >
        <Box
          bg={activeHint === Hint.PINCH_ZOOM ? '#FFFFFF' : 'transparent'}
          borderRadius="24px"
          position="relative"
          width={{ base: '300px', md: '500px' }}
          zIndex={activeHint === Hint.PINCH_ZOOM ? 4 : 'auto'}
        />
      </HintPopover>
      <Box
        position="absolute"
        left={`${clipPath ? 0 : drawingArea.left}px`}
        top={`${clipPath ? 0 : drawingArea.top}px`}
      >
        <Box bg="transparent" borderRadius="7px" h="100%" w="100%">
          <canvas id={`canvas-${side}`}></canvas>
        </Box>
      </Box>
    </F>
  );
};

export default CanvasContainer;
