import {
  Box,
  Button as ChakraButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';

import IconChevronDown from '@/components/icons/IconChevronDown';
import FormLabel from '@/components/form/FormLabel';

import { Option } from '@/components/types';

const buttonActiveStyle = {
  boxShadow: 'none',
};

type DropdownProps<T> = {
  name?: string;
  options: Option<T>[];
  selectedValue: T;
  onSelectedValue: (value: T) => void;
  width?: string;
  isOptional?: boolean;
};

const Dropdown = function <T>({
  name,
  options,
  selectedValue,
  onSelectedValue,
  width,
  isOptional = false,
  ...rest
}: DropdownProps<T>) {
  return (
    <Box width={width || 'auto'}>
      {name ? <FormLabel isOptional={isOptional}>{name}</FormLabel> : null}
      <Menu placement="bottom">
        <MenuButton
          as={ChakraButton}
          bg="transparent"
          border="1px solid"
          borderColor="secondaryGray.500"
          borderRadius="16px"
          color="black.600"
          fontSize="sm"
          fontWeight={400}
          h="50px"
          rightIcon={<IconChevronDown />}
          minWidth="none"
          onClick={(e) => e.stopPropagation()}
          p="15px 0 15px 11px"
          textAlign="left"
          w="100%"
          {...rest}
          _hover={buttonActiveStyle}
          _focus={buttonActiveStyle}
          _active={buttonActiveStyle}
        >
          {options.find((option) => option.id === selectedValue)?.name}
        </MenuButton>
        <MenuList
          bg="#FFFFFF"
          border="1px solid"
          borderColor="secondaryGray.300"
          minWidth="none"
          p="0"
          width={width}
        >
          {options.map(({ name, id }, index) => (
            <MenuItem
              key={index}
              fontSize="sm"
              fontWeight={400}
              onClick={() => onSelectedValue(id)}
              padding="8px 18px"
              _focus={{
                bg: '#F4F4F4',
              }}
              _active={{
                bg: 'none',
              }}
            >
              {name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default Dropdown;
