import { useEffect, useState } from 'react';

import Pubnub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';

import AdminDashboard from './AdminDashboard';
import { useMe } from '@/api/auth';
import Config from '@/config';
import { Center, Spinner } from '@chakra-ui/react';

const { PUBNUB_PUBLISH_KEY, PUBNUB_SUBSCRIBE_KEY } = Config;

export default function Admin() {
  const [pubnubClient, setPubNubClient] = useState(null);

  const { data: me, isLoading } = useMe();

  useEffect(() => {
    if (isLoading || pubnubClient) {
      return;
    }

    const pubnub = new Pubnub({
      publishKey: PUBNUB_PUBLISH_KEY,
      subscribeKey: PUBNUB_SUBSCRIBE_KEY,
      userId: me?.id || 'anonymous',
    });

    setPubNubClient(pubnub);
  }, [me, isLoading, pubnubClient]);

  if (isLoading || !pubnubClient) {
    return (
      <Center bg="transparent" h="100vh">
        <Spinner thickness="1px" speed="0.65s" emptyColor="gray" color="brand.500" size="md" />
      </Center>
    );
  }

  return (
    <PubNubProvider client={pubnubClient}>
      <AdminDashboard me={me} />
    </PubNubProvider>
  );
}
