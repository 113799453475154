import { User } from '@/lib';
import { Center, HStack, Image, Text } from '@chakra-ui/react';

const AVATAR_MOBILE_SIZE = 24;
const AVATAR_DESKTOP_SIZE = 24;

interface CreatorAvatarProps {
  color?: string;
  maxW?: string;
  user: User;
}

const AvatarStyles = {
  borderRadius: '50%',
  w: `${AVATAR_MOBILE_SIZE}px`,
  h: `${AVATAR_DESKTOP_SIZE}px`,
};

export default function CreatorAvatar({ color, maxW, user }: CreatorAvatarProps) {
  const { avatarUrl, firstName, lastName } = user || {};

  return (
    <HStack mt="2px" spacing="5px">
      {avatarUrl ? (
        <Image src={avatarUrl} {...AvatarStyles} />
      ) : (
        <Center bg="#34C759" {...AvatarStyles}>
          <Text fontSize="10px" fontWeight={500}>
            {user ? `${firstName[0]}` : 'A'}
          </Text>
        </Center>
      )}
      <Text
        color={color}
        fontWeight={500}
        fontSize="md"
        maxW={maxW || 'none'}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {user ? `${firstName || ''} ${lastName || ''}` : 'Anonymous'}
      </Text>
    </HStack>
  );
}
