import { HStack } from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';

import { isAfter, subMonths, subWeeks } from 'date-fns';

import { Design } from '@/lib/types';

import StatBox from './StatBox';
import { trackEvent } from '../../../../analytics';
import { Event } from '../../../../analytics/events';

const getDesignsFromLastMonth = (designs: Design[]) => {
  const monthAgo = subMonths(new Date(), 1);

  return designs.filter(({ createdAt }) => isAfter(new Date(createdAt), monthAgo));
};

const getDesignsFromLastWeek = (designs: Design[]) => {
  const weekAgo = subWeeks(new Date(), 1);

  return designs.filter(({ createdAt }) => isAfter(new Date(createdAt), weekAgo));
};

const getStatBoxes = (designs: Design[] = []) => {
  const name = 'Designs Created';

  return [
    {
      name: `${name} (All Time)`,
      value: `${designs.length}`.toLocaleString(),
    },
    {
      name: `${name} (Last Month)`,
      value: `${getDesignsFromLastMonth(designs).length}`.toLocaleString(),
    },
    {
      name: `${name} (Last Week)`,
      value: `${getDesignsFromLastWeek(designs).length}`.toLocaleString(),
    },
  ];
};

type StatsProps = {
  designs: Design[];
};

export default function Stats({ designs }: StatsProps) {
  const history = useHistory();

  return (
    <HStack align="flex-start" w="100%">
      {getStatBoxes(designs).map(({ name, value }) => (
        <StatBox
          key={name}
          name={name}
          onClick={() => {
            history.push('/designs');
            trackEvent(Event.STAT_BOX_CLICKED + `: ${name}`);
          }}
          value={value}
        />
      ))}
    </HStack>
  );
}
