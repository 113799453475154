import { useEffect, useState } from 'react';

import { Center, Link, Spinner, VStack, Text } from '@chakra-ui/react';

import { useLocation } from 'react-router-dom';

import { verifyEmail } from '@/api/auth';

import { Event } from '../../../analytics/events';
import AuthContainer from '../components/AuthContainer';
import ErrorMessage from '../components/ErrorMessage';
import { handleSignIn } from '../utils';

function VerifyEmail() {
  const { search } = useLocation();

  const [error, setError] = useState(null);
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);

    const token = searchParams.get('token');

    setWaiting(true);

    verifyEmail(token)
      .then(({ access_token: accessToken, user }) => {
        handleSignIn(Event.LOGGED_IN, user, accessToken, false);

        window.location.href = '/';
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setWaiting(false);
      });
  }, []);

  if (waiting) {
    return (
      <Center bg="transparent" flex={1} h="100vh">
        <Spinner thickness="1px" speed="0.65s" emptyColor="gray" color="brand.500" size="md" />
      </Center>
    );
  }

  return (
    <AuthContainer title="" subtitle={!waiting && !error ? 'Your email has been verified.' : null}>
      {waiting ? (
        <Center bg="transparent" h="100vh">
          <Spinner thickness="1px" speed="0.65s" emptyColor="gray" color="brand.500" size="md" />
        </Center>
      ) : (
        <VStack mb="20px" mt="20px" spacing="24px" maxW="350px" minH="400px" pt="32px">
          {error ? (
            <ErrorMessage
              message={
                <Text>
                  Your token is either invalid or expired. Please{' '}
                  <Link
                    color="brand.500"
                    fontWeight={700}
                    href="/auth/forgot-password"
                    ml="4px"
                    textDecoration="underline"
                  >
                    reset
                  </Link>{' '}
                  your password.
                </Text>
              }
            />
          ) : null}
        </VStack>
      )}
    </AuthContainer>
  );
}

export default VerifyEmail;
