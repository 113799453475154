import { Box, HStack, Stack, Text, VStack } from '@chakra-ui/react';

import { useParams } from 'react-router-dom';

import Button from '@/components/button';

import { useDesign } from '@/api/designs';
import { DesignSide } from '@/lib';

import IconPerson from '@/components/icons/IconPerson';

import './components/DesignPreviewGallery';
import DesignPreviewGallery from './components/DesignPreviewGallery';

interface DesignPreviewProps {
  onDuplicate: () => void;
}

export default function DesignPreview({ onDuplicate }: DesignPreviewProps) {
  const { id: designId } = useParams<{ id: string }>();

  const { data: design } = useDesign(designId);

  const { name, sides = [], user } = design || {};

  const { firstName = '', lastName = '' } = user || {};

  const designSide = sides[0] as DesignSide;

  const { templateSide } = designSide || {};

  const { manufacturingImageWidth, manufacturingImageHeight } = templateSide || {};

  return (
    <VStack p="16px" overflow="auto" w="100%">
      <Stack
        bg="#FFFFFF"
        borderRadius="14px"
        boxShadow="0px 4px 15px 0px rgba(0, 52, 139, 0.15)"
        direction={{ base: 'column', md: 'row' }}
        mb="16px"
        p="12px 32px"
        spacing="24px"
      >
        <Box w="252px">
          <HStack wrap="wrap">
            <Text fontWeight={600}>{name}</Text>
            {manufacturingImageWidth && manufacturingImageHeight ? (
              <Text>
                {manufacturingImageWidth} x {manufacturingImageHeight} px
              </Text>
            ) : null}
          </HStack>
          <HStack spacing="4px">
            <Text>Created by</Text>
            <IconPerson />
            <Text fontWeight={600}>{`${firstName} ${lastName}`}</Text>
          </HStack>
        </Box>
        <Button onClick={onDuplicate} outlined>
          Duplicate & Edit
        </Button>
      </Stack>
      {design ? <DesignPreviewGallery design={design} /> : null}
    </VStack>
  );
}
