import { useEffect, useState } from 'react';

import { Box, Center, Spinner } from '@chakra-ui/react';

import { Dictionary } from 'lodash';

import { getDesigns } from '@/api/designs';
import { useBasicTemplates } from '@/api/templates';

import { Template } from '@/components/types';
import { Design } from '@/lib';

import DesignDetailsModal from './DesignDetailsModal';

import TemplateFilterPicker from '../components/TemplateFilterPicker';

import DesignsList from './DesignsList';
import AppContainer from '@/layouts/AppContainer';
import { useMe } from '../../../api/auth';

const matchesClothingType = (types: string[], template: Template) =>
  !types?.length || types.find((type) => type.includes(template.name));

const matchesColor = (colors: string[], templateColorId: string, template) => {
  const colorName = template.colors.find(({ id }) => id === templateColorId)?.name;

  return !colors?.length || colors.find((color) => color === colorName);
};

const matchesSize = (sizes: number[], sizeId: number) =>
  !sizes?.length || sizes.find((id) => sizeId === id);

const matchesSearch = (search: string, name) =>
  !search || (name || '').toLowerCase().includes(search.toLowerCase());

const getDesignsMatchingFilters = (designs: Design[], filters: Dictionary<[]>, search: string) =>
  designs.filter((design) => {
    const { template, templateColorId, sizeId, name } = design;

    const { type, color, size } = filters;

    return (
      matchesClothingType(type, template as Template) &&
      matchesColor(color, templateColorId, template) &&
      matchesSize(size, sizeId) &&
      matchesSearch(search, name)
    );
  });

export default function DesignCenter() {
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState('');
  const { data: me } = useMe();

  const [isLoading, setLoading] = useState(true);
  const [designs, setDesigns] = useState<Design[]>([]);

  useEffect(() => {
    getDesigns({ clientId: me.client.id }).then((designs) => {
      setDesigns(designs);

      setLoading(false);
    });
  }, []);

  const { data: templates, isLoading: areTemplatesLoading } = useBasicTemplates({
    clientId: me.client.id,
  });

  const handleDesignUpdated = ({ id, ...rest }) =>
    setDesigns(
      designs.map((design) => {
        if (design.id === id) {
          return {
            ...design,
            ...rest,
          };
        }

        return design;
      })
    );

  const handleDesignDeleted = (designId) => {
    setDesigns(designs.filter(({ id }) => id !== designId));
  };

  const designsMatchingFilters =
    designs && templates ? getDesignsMatchingFilters(designs, filters, search) : [];

  return (
    <AppContainer>
      {isLoading || areTemplatesLoading ? (
        <Center bg="transparent" h="calc(100vh - 100px)">
          <Spinner thickness="1px" speed="0.65s" emptyColor="gray" color="brand.500" size="md" />
        </Center>
      ) : (
        <Box>
          <TemplateFilterPicker
            filters={filters}
            onSelectedFilters={setFilters}
            search={search}
            onSearchChange={setSearch}
            templates={templates || []}
          />
          <DesignsList
            onSelectedDesign={setSelectedDesign}
            onDesignDeleted={handleDesignDeleted}
            onDesignUpdated={handleDesignUpdated}
            designs={designsMatchingFilters}
            templates={templates}
          />
        </Box>
      )}
      {selectedDesign ? (
        <DesignDetailsModal
          designId={selectedDesign.id}
          onClose={() => setSelectedDesign(null)}
          templates={templates}
        />
      ) : null}
    </AppContainer>
  );
}
