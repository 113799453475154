import { useEffect, useState } from 'react';

import {
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  VStack,
  Text,
  useToast,
  HStack,
} from '@chakra-ui/react';

import { useUpdateUser } from '@/api/users';
import IconCloseModalSmall from '@/components/icons/IconCloseModal';
import Dropdown from '@/components/form/Dropdown';
import { User, UserRole } from '@/components/types';

import Button from '@/components/button';

import { formatRole } from '../utils/formatters';
import { AxiosError } from 'axios';
import FormInput from '../../../components/form/FormInput';

type Props = {
  onClose: () => void;
  roles: UserRole[];
  user: User;
};

function UpdateUserModal({ onClose, roles = [], user: initialUser }: Props) {
  const [user, setUser] = useState<User>(null);

  const [waiting, setWaiting] = useState(false);

  const toast = useToast();

  const { updateUser } = useUpdateUser();

  useEffect(() => {
    setUser(initialUser);
  }, [initialUser]);

  const handleRoleUpdate = (roleId) => {
    const newRoles = roles.filter(({ id }) => id === roleId);

    setUser({
      ...user,
      roles: newRoles,
    });
  };

  const handleFirstnameUpdate = (firstName: string) => {
    setUser({
      ...user,
      firstName,
    });
  };

  const handleLastnameUpdate = (lastName: string) => {
    setUser({
      ...user,
      lastName,
    });
  };

  const handleEmailUpdate = (email: string) => {
    setUser({
      ...user,
      email,
    });
  };

  const handleSave = () => {
    setWaiting(true);
    const { email, firstName, lastName } = user;
    updateUser(
      {
        id: user.id,
        email,
        firstName,
        lastName,
        roleId: user.roles[0].id,
      },
      {
        onSuccess: () => {
          toast({
            title: 'Changes saved',
            status: 'success',
            duration: 2000,
          });
          setWaiting(false);
          onClose();
        },
        onError: (err: AxiosError) => {
          let message = 'Error saving changes';
          if (err?.response?.data) {
            const errors = err?.response?.data['message'];
            if (errors && errors.length) {
              let errorText = '';
              if (Array.isArray(errors)) {
                errorText = errors.join(', ');
              } else {
                errorText = errors;
              }
              message += `: ${errorText}`;
            }
          }

          toast({
            title: message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });

          setWaiting(false);
        },
      }
    );
  };
  return (
    <Modal isOpen={true} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent alignSelf="center" borderRadius="10px" maxWidth="749px">
        <ModalBody padding="50px 66px">
          <Flex alignItems="center" justifyContent="space-between">
            <Box>
              <Text as="b" color="black.700" fontSize="26px" fontWeight={700} mb="12px">
                Edit member detail
              </Text>
            </Box>
            <Button bg="transparent" minWidth="none" onClick={onClose} padding={0} tabIndex={-1}>
              <IconCloseModalSmall />
            </Button>
          </Flex>
          <VStack align="flex-start" height="200px" justify="space-between" pt="30px" spacing={0}>
            <HStack>
              <FormInput
                placeholder="First Name"
                value={user?.firstName}
                onChange={(e) => handleFirstnameUpdate(e.target.value)}
              />
              <FormInput
                placeholder="Last Name"
                value={user?.lastName}
                onChange={(e) => handleLastnameUpdate(e.target.value)}
              />
              <Dropdown
                options={roles.map((role) => ({ id: role.id, name: formatRole(role.name) }))}
                onSelectedValue={handleRoleUpdate}
                selectedValue={user?.roles[0]?.id}
                width="250px"
              />
            </HStack>
            <FormInput
              placeholder="Email"
              value={user?.email}
              onChange={(e) => handleEmailUpdate(e.target.value)}
            />
            <Button isLoading={waiting} isDisabled={!roles.length} onClick={handleSave} w="128px">
              Save
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default UpdateUserModal;
