import axios from 'axios';

import { ImageToImageRequest, TextToImageRequest, TextToImageResponse } from '@/lib/types';

export const generateImageFromText = (params: TextToImageRequest): Promise<TextToImageResponse> => {
  return axios
    .post('/generate/text-to-image', params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => {
      return data;
    });
};

export const generateImageFromImage = (params: ImageToImageRequest) =>
  axios
    .post(`/generate/image-file-to-image`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => {
      return data.images;
    });

export const urlToUpscale = (imageUrl: string, scale: number): Promise<string> =>
  axios
    .post('/generate/url-to-upscale', {
      imageUrl,
      scale,
    })
    .then(({ data }) => {
      return data.image;
    });

export const imageToUpscale = (imageFile: File, scale: number): Promise<string> =>
  axios
    .post(
      '/generate/image-to-upscale',
      {
        image: imageFile,
        scale,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then(({ data }) => {
      return data.image;
    });
