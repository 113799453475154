import React, { useEffect, useState } from 'react';

import { NavLink, useLocation } from 'react-router-dom';

import {
  Button,
  Box,
  Flex,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
  HStack,
  Popover,
  PopoverTrigger,
  VStack,
  Text,
  Show,
} from '@chakra-ui/react';

import routes from '@/layouts/admin/routes';

import IconToggle from './IconToggle';
import PopoverDemoHint from './PopoverDemoHint';
import IconDrawerMenu from '../icons/IconDrawerMenu';
import CreditsPill from './CreditsPill';

const HAS_SEEN_DEMO_HINT = 'HAS_SEEN_DEMO_HINT';

type SidebarContentProps = {
  isCollapsed?: boolean;
  onChangeCollapsed?: (isCollapsed: boolean) => void;
};

const SidebarText = ({ isActive, name }) => (
  <Text
    me="auto"
    color={isActive ? 'black.700' : 'secondaryGray.600'}
    fontWeight={isActive ? 700 : 500}
  >
    {name}
  </Text>
);

const SidebarContent = ({ isCollapsed, onChangeCollapsed }: SidebarContentProps) => {
  const location = useLocation();

  const [isDemoHintVisible, setDemoHintVisible] = useState(false);

  const isActiveRoute = (route) => {
    const { pathname } = location;
    const routePath = route.path.toLowerCase();

    const parts = pathname.split('/').slice(1);

    if (parts[0] === 'designs' && parts[1]) {
      return route.name === 'Generate Art';
    }

    if (route.exact) {
      return pathname === routePath;
    }

    return pathname.includes(routePath);
  };

  const handleDismissDemoHint = () => {
    localStorage.setItem(HAS_SEEN_DEMO_HINT, 'true');

    setDemoHintVisible(false);
  };

  useEffect(() => {
    if (!localStorage.getItem(HAS_SEEN_DEMO_HINT)) {
      setDemoHintVisible(true);
    }
  }, []);

  return (
    <Flex borderRight="1px solid #E2E8F0" direction="column" height="100%" pt="20px">
      <Flex justify="space-between" pl="24px" pb={{ base: '15px', md: 0 }} pr="15px">
        {!isCollapsed ? <CreditsPill /> : null}
        <Show above="lg">
          <Button bg="transparent" onClick={() => onChangeCollapsed(!isCollapsed)} padding={0}>
            <IconToggle isRotated={isCollapsed} />
          </Button>
        </Show>
      </Flex>
      <Flex h="1px" bg="#F4F7FE" mt="6px" />
      <VStack align="flex-start" mt="21px" pl="31px" spacing="20px">
        {routes
          .filter(({ notInMenu }) => !notInMenu)
          .map((route, index) => {
            const { defaultPath, icon, iconActive, name, path } = route;

            const isActive = isActiveRoute(route);

            const showsDemoHint = name === 'Generate Art' && isDemoHintVisible;

            return (
              <NavLink
                key={index}
                to={showsDemoHint ? '' : defaultPath || path}
                style={{ width: '100%' }}
              >
                <HStack>
                  <HStack pr={isCollapsed ? '28px' : 0} spacing="12px" w="100%" justify="center">
                    {isActive ? iconActive : icon}
                    {!isCollapsed ? (
                      showsDemoHint ? (
                        <Popover
                          returnFocusOnClose={false}
                          isOpen
                          placement="right"
                          onClose={handleDismissDemoHint}
                        >
                          <PopoverTrigger>
                            <Text
                              me="auto"
                              color={isActive ? 'black.700' : 'secondaryGray.600'}
                              fontWeight={isActive ? 700 : 500}
                            >
                              {name}
                            </Text>
                          </PopoverTrigger>
                          <PopoverDemoHint
                            onClose={handleDismissDemoHint}
                            onGoToDemo={handleDismissDemoHint}
                          />
                        </Popover>
                      ) : (
                        <SidebarText name={name} isActive={isActive} />
                      )
                    ) : null}
                  </HStack>
                  <Box h="36px" w="4px" borderRadius="25px" />
                </HStack>
              </NavLink>
            );
          })}
      </VStack>
    </Flex>
  );
};

type SidebarProps = {
  isCollapsed?: boolean;
  onChangeCollapsed?: (isCollapsed: boolean) => void;
};

const Sidebar = ({ isCollapsed, onChangeCollapsed }: SidebarProps) => {
  return (
    <Box
      bg="#FFFFFF"
      transition="0.2s linear"
      w={`${isCollapsed ? 100 : 300}px`}
      h="calc(100vh - 80px)"
      overflowX="hidden"
      boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
      display={{ sm: 'none', lg: 'block' }}
    >
      <SidebarContent isCollapsed={isCollapsed} onChangeCollapsed={onChangeCollapsed} />
    </Box>
  );
};

export const SidebarResponsive = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <Flex display={{ sm: 'flex', lg: 'none' }} alignItems="center">
      <Flex ref={btnRef} w="max-content" h="max-content" onClick={onOpen}>
        <IconDrawerMenu />
      </Flex>
      <Drawer isOpen={isOpen} onClose={onClose} finalFocusRef={btnRef} placement="left">
        <DrawerOverlay />
        <DrawerContent w="285px" maxW="285px" bg="#FFFFFF">
          <DrawerCloseButton
            zIndex="3"
            _focus={{ boxShadow: 'none' }}
            _hover={{ boxShadow: 'none' }}
          />
          <DrawerBody maxW="285px" px="0rem" pb="0">
            <SidebarContent />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default Sidebar;
