import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  VStack,
} from '@chakra-ui/react';

import { format, parseISO } from 'date-fns';
import { chunk } from 'lodash';

import { Swiper, SwiperSlide } from 'swiper/react';

import { Pagination } from 'swiper';

import { useSizes } from '@/api/sizes';
import IconCloseModalSmall from '@/components/icons/IconCloseModal';

import { Design } from '@/lib/types';
import { Template } from '@/components/types';
import { DESIGN_TEMPLATE_REFERENCE_WIDTH } from '@/constants';

import 'swiper/css';
import 'swiper/css/pagination';
import { useEffect, useState } from 'react';
import { getDesign } from '@/api/designs';

const getProperties = (design, sizes) => {
  const { createdAt, templateColorId, sizeId, template } = design;

  const { colors, fabric, name, price } = template;

  const colorName = colors.find(({ id }) => id === templateColorId).name;

  const size = sizes.find(({ id }) => id === sizeId)?.name;

  return [
    {
      name: 'Template used',
      value: name,
    },
    {
      name: 'Price',
      value: price,
    },
    {
      name: 'Size',
      value: size,
    },
    {
      name: 'Color',
      value: colorName,
    },
    {
      name: 'Created date',
      value: format(parseISO(createdAt), 'MMM d, y h:m a'),
    },
    {
      name: 'Fabric',
      value: fabric,
    },
  ];
};

type Props = {
  designId: string;
  onClose: () => void;
  templates: Template[];
};

const DesignDetailsModal = ({ designId, onClose }: Props) => {
  const [design, setDesign] = useState<Design>(null);

  useEffect(() => {
    getDesign(designId).then((design) => setDesign(design));
  }, []);

  const { sides = [] } = design || {};

  const { data: sizes = [] } = useSizes();

  const properties = design ? getProperties(design, sizes) : [];

  const chunks = chunk(properties, 2);

  return (
    <Modal isOpen={true} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent alignSelf="center" borderRadius="10px" maxH="fit-content" maxW="848px">
        <ModalBody padding="50px 66px">
          <Flex alignItems="center" justifyContent="space-between" w="100%">
            <Box>
              <Text as="b" color="black.700" fontSize="26px" fontWeight={700} mb="12px">
                Details
              </Text>
              <Text color="secondaryGray.600" fontSize="md">
                Here are the details of the design
              </Text>
            </Box>
            <Button bg="transparent" minWidth="none" onClick={onClose} padding={0} tabIndex={-1}>
              <IconCloseModalSmall />
            </Button>
          </Flex>
          <Flex h="400px" justify="center" w="100%">
            <Box h="100%" w={{ base: '100%', md: '400px' }}>
              <Swiper height={400} pagination modules={[Pagination]} className="mySwiper">
                {sides.map((side, index) => {
                  const { designImage, previewImage } = side;

                  return (
                    <SwiperSlide key={index}>
                      <Box h={400} position="relative">
                        <Image
                          src={designImage || previewImage}
                          position="absolute"
                          width={DESIGN_TEMPLATE_REFERENCE_WIDTH}
                        />
                      </Box>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Box>
          </Flex>
          <VStack spacing="20px">
            {chunks.map((chunk, index) => (
              <Flex key={index} w="100%">
                {chunk.map(({ name, value }, index) => (
                  <Card
                    bg="#FFFFFF"
                    borderRadius="16px"
                    boxShadow="0px 18px 40px 0px rgba(112, 144, 176, 0.12)"
                    flex={1}
                    key={index}
                    mr={index === 0 ? '24px' : 0}
                  >
                    <CardBody p="12px 22px">
                      <Text color="secondaryGray.600" fontSize="sm">
                        {name}
                      </Text>
                      <Text color="black.700">{value || '-'}</Text>
                    </CardBody>
                  </Card>
                ))}
              </Flex>
            ))}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DesignDetailsModal;
