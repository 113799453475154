import { Center, Flex, HStack, Text, VStack, useToast } from '@chakra-ui/react';

import { DragDropContext, Draggable } from 'react-beautiful-dnd';

import Droppable from '@/components/drag-and-drop/Droppable';
import IconDragHandle from '@/components/drag-and-drop/IconDragHandle';
import IconUpload from '@/components/icons/IconUpload';
import LoadingOverlay from '@/components/modals/LoadingOverlay';

import ImageUpload from '@/lib/components/upload/ImageUpload';
import { Template } from '@/components/types';
import { TemplatePreviewImage } from '@/lib';

import {
  createTemplatePreviewImage,
  updateTemplatePreviewImage,
  deleteTemplatePreviewImage,
} from '@/api/templates';

import ButtonDelete from '../components/ButtonDelete';
import FormContainer from '../components/FormContainer';

import ImageUploadButton from '../components/ImageUploadButton';
import { getListStyle, getItemStyle, reorder } from '../components/DragAndDrop';
import { random } from 'lodash';
import { useState } from 'react';

type PreviewImagesProps = {
  onNext: () => void;
  onUpdate: (images: TemplatePreviewImage[]) => void;
  template: Template;
};

const PreviewImages = ({ onNext, onUpdate, template }: PreviewImagesProps) => {
  const toast = useToast();

  const [waiting, setWaiting] = useState(false);

  const { previewImages: images = [] } = template;

  const handleRemoveImage = (index: number) => {
    const imageToRemove = images[index];

    if (template.id && imageToRemove.id) {
      deleteTemplatePreviewImage(template.id, imageToRemove.id).then(() =>
        handleImageRemoved(index)
      );

      return;
    }

    handleImageRemoved(index);
  };

  const handleImageRemoved = (indexToRemove: number) => {
    const newImages = images.filter((image, index) => {
      return image && index !== indexToRemove;
    });

    onUpdate(newImages);
  };

  const handleUpdateImage = (index: number, newImage: string) => {
    const image = images[index];

    setWaiting(true);

    if (template.id) {
      updateTemplatePreviewImage(template.id, image.id, {
        image: newImage,
      }).then(({ url }) => {
        showToast();

        setWaiting(false);

        handleUpdatePreviewImage(index, { url });
      });
    } else {
      handleUpdatePreviewImage(index, { image: newImage });
    }
  };

  const handleUpdatePreviewImage = (indexToUpdate, updates) => {
    const newImages = images.map((image, index) => {
      if (index === indexToUpdate) {
        return {
          ...image,
          ...updates,
        };
      }

      return image;
    });

    onUpdate(newImages);
  };

  const handleImagesUploaded = (uploadedImages: HTMLImageElement[]) => {
    if (!template.id) {
      onUpdate([
        ...images,
        ...uploadedImages.map((image, index) => ({
          order: images.length + index,
          image: image.src,
          id: `${random(0, 10e5)}`,
        })),
      ]);

      return;
    }

    setWaiting(true);

    Promise.all(
      uploadedImages.map((image, index) =>
        createTemplatePreviewImage(template.id, { image: image.src, order: index })
      )
    ).then((newImages) => {
      setWaiting(false);

      onUpdate([...images, ...newImages]);
    });
  };

  const handleNext = () => {
    onNext();
  };

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newImages = reorder(images, result.source.index, result.destination.index).map(
      (item, index) => ({
        ...item,
        order: index,
      })
    );

    if (!template.id) {
      onUpdate(newImages);

      return;
    }

    Promise.all(
      newImages.map((image) =>
        updateTemplatePreviewImage(template.id, image.id, { order: image.order })
      )
    ).then(() => {
      showToast();
    });

    onUpdate(newImages);
  };

  const showToast = () =>
    toast({
      title: 'Changes saved',
      position: 'top',
      status: 'success',
    });

  return (
    <FormContainer
      description="Files supported (PNG at 72dpi minimum)"
      onNext={!template.id && handleNext}
      title="Upload product image"
    >
      <ImageUpload
        customButtonContent={
          <Center
            width="287px"
            h="82px"
            borderRadius="6px"
            border="1px dashed"
            borderColor="secondaryGray.500"
            cursor="pointer"
          >
            <VStack spacing="10px">
              <IconUpload />
              <Text color="secondaryDarkGray.500" fontSize="10px" fontWeight={400}>
                Click here to upload or Drag & Drop
              </Text>
            </VStack>
          </Center>
        }
        multiple
        onImagesUploaded={(images) => handleImagesUploaded(images)}
      />
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <div style={{ display: 'none' }}>{provided.placeholder}</div>
              <VStack align="flex-start" mt="20px" spacing="20px" w="600px">
                {images.map((previewImage, index) => {
                  const { id, image, url } = previewImage;

                  return (
                    <Draggable key={`key-${id || image}`} draggableId={`ID-${id}`} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          {provided.placeholder}
                          <Flex align="center" justify="space-between" key={index} w="100%" mb={8}>
                            <HStack flex={1} key={index} spacing="14px">
                              <div {...provided.dragHandleProps}>
                                <IconDragHandle />
                              </div>
                              <ImageUploadButton
                                image={url || image}
                                onImageUploaded={(image) => handleUpdateImage(index, image)}
                              />
                            </HStack>
                            <ButtonDelete onClick={() => handleRemoveImage(index)} />
                          </Flex>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </VStack>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {waiting ? <LoadingOverlay /> : null}
    </FormContainer>
  );
};

export default PreviewImages;
