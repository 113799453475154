import { Show } from '@chakra-ui/react';
import Home from './Home';
import AppContainer from '@/layouts/AppContainer';
import ButtonBack from '@/lib/components/navbar/ButtonBack';
import { useHistory, useLocation } from 'react-router-dom';

export default function HomeSignedIn() {
  const history = useHistory();

  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);

  return (
    <AppContainer
      contentContainerProps={{ padding: 0 }}
      rightSideNavbarContent={
        queryParams.get('collectionId') ? (
          <Show above="md">
            <ButtonBack onClick={() => history.replace('/')} />
          </Show>
        ) : null
      }
    >
      <Home />
    </AppContainer>
  );
}
