import { ReactNode, useEffect, useState } from 'react';

import { Box, BoxProps, Flex } from '@chakra-ui/react';

import { StorageKeys } from '@/constants';

import Sidebar from '@/components/sidebar';
import Navbar from '@/components/navbar';

const { IS_SIDEBAR_COLLAPSED } = StorageKeys;

interface AppContainerProps {
  children: ReactNode;
  hideSidebar?: boolean;
  rightSideNavbarContent?: ReactNode;
  contentContainerProps?: BoxProps;
}

export default function AppContainer({
  children,
  hideSidebar,
  contentContainerProps,
  rightSideNavbarContent,
}: AppContainerProps) {
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);

  const handleToggleSidebarCollapsed = (isCollapsed) => {
    localStorage.setItem(IS_SIDEBAR_COLLAPSED, isCollapsed);

    setSidebarCollapsed(isCollapsed);
  };

  const isCollapsed = JSON.parse(localStorage.getItem(IS_SIDEBAR_COLLAPSED));

  useEffect(() => {
    setSidebarCollapsed(isCollapsed);
  }, [isCollapsed]);

  return (
    <Box>
      <Navbar rightSideContent={rightSideNavbarContent} />
      <Flex w="100%">
        {!hideSidebar ? (
          <Sidebar
            isCollapsed={isSidebarCollapsed}
            onChangeCollapsed={handleToggleSidebarCollapsed}
          />
        ) : null}
        <Box
          flex={1}
          h={{ base: 'calc(100vh - 70px)', md: 'calc(100vh - 80px)' }}
          overflow="auto"
          p="20px"
          {...contentContainerProps}
        >
          {children}
        </Box>
      </Flex>
    </Box>
  );
}
