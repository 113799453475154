import { Box, ButtonProps, Flex, Spinner } from '@chakra-ui/react';
import { AbloImage } from '../../../types';
import Button from '../../../components/Button';
import { IconAi } from '../../../components/icons/IconAi';
import CreditCostIcon from '../components/CreditCostIcon';

export interface GenerateButtonsProps {
  actionCost: number;
  buttonProps?: {
    basic: ButtonProps;
    outlined: ButtonProps;
  };
  freeText?: string;
  images: AbloImage[];
  isGenerating: boolean;
  handleGenerate: (isSimilar?: boolean) => void;
  styleId?: string;
}

export const GenerateButtons: React.FC<GenerateButtonsProps> = ({
  actionCost,
  buttonProps,
  freeText,
  handleGenerate,
  isGenerating,
  images,
  styleId,
}) => {
  const isDisabled = !freeText || !styleId || isGenerating;

  return (
    <>
      {images.length > 0 ? (
        <Flex align="center" m="14px 0">
          <Button
            flex={1}
            iconRight={<CreditCostIcon cost={actionCost} outlined />}
            onClick={() => handleGenerate(true)}
            variant="outlined"
            title="Generate Similar"
            {...(buttonProps?.outlined || {})}
          />
          <Button
            flex={1}
            iconRight={<CreditCostIcon cost={actionCost} />}
            ml="10px"
            onClick={() => handleGenerate()}
            title="Re-Generate"
            {...(buttonProps?.outlined || {})}
            icon={<IconAi />}
          />
        </Flex>
      ) : (
        <Box mt="22px">
          <Button
            isDisabled={isDisabled}
            onClick={() => handleGenerate()}
            title="Generate"
            w="100%"
            {...(buttonProps?.basic || {})}
            icon={isGenerating ? <Spinner /> : null}
            iconRight={!isDisabled ? <CreditCostIcon cost={actionCost} /> : null}
          />
        </Box>
      )}
    </>
  );
};
