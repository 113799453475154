import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  VStack,
  Image,
} from '@chakra-ui/react';

import Button from '@/components/button';

import CoinBlue from '@/assets/CoinBlue.png';
import { useHistory } from 'react-router-dom';

type Props = {
  onClose: () => void;
};

const OutOfCreditsModal = ({ onClose }: Props) => {
  const history = useHistory();

  const handleConfirm = () => {
    onClose();

    history.push('/subscription');
  };

  return (
    <Modal isOpen={true} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent alignSelf="center" borderRadius="20px" maxWidth="527px" maxHeight="283px">
        <ModalBody padding="40px">
          <VStack spacing="14px">
            <Image src={CoinBlue} w="32px" />
            <Text color="gray.900" fontSize="24px" fontWeight={600}>
              You're out of credits
            </Text>
            <Text color="gray.600" fontSize="sm">
              It looks like you've used up all your credits. No worries, topping up is easy and
              quick. Keep enjoying our services without a hitch!
            </Text>
            <Button mt="26px" onClick={handleConfirm}>
              Subscribe
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default OutOfCreditsModal;
