import { Text, VStack } from '@chakra-ui/react';

import { ReactNode } from 'react';

import { FileUploader } from 'react-drag-drop-files';
import { UploadButton } from './UploadButton';

const getImageFromFile = (fileObj: File): Promise<HTMLImageElement> =>
  new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      const image = new Image();

      image.src = e.target.result as string;

      image.onload = function () {
        resolve(image);
      };
    };

    reader.readAsDataURL(fileObj);
  });

type ImageUploadProps = {
  customButtonContent?: ReactNode;
  onFilesSelected?: (file: File[]) => void;
  onImagesUploaded?: (images: HTMLImageElement[]) => void;
  multiple?: boolean;
  label?: string;
};

export default function ImageUpload({
  customButtonContent,
  onFilesSelected: onFilesUploaded,
  onImagesUploaded,
  multiple = false,
  label = 'Upload photo',
}: ImageUploadProps) {
  const handleFileChange = (files: File | FileList) => {
    const fileObjects = files instanceof File ? [files] : files;

    if (!fileObjects || !fileObjects[0]) {
      return;
    }

    if (onImagesUploaded) {
      Promise.all([...fileObjects].map((fileObj) => getImageFromFile(fileObj))).then((images) => {
        onImagesUploaded(images);
      });
    }

    if (onFilesUploaded) {
      onFilesUploaded([...fileObjects]);
    }
  };

  return (
    <FileUploader handleChange={handleFileChange} multiple={multiple} name="file">
      {customButtonContent || (
        <VStack>
          <UploadButton />
          <Text color="#1A1A1A" fontSize="11px" align="center">
            {label}
          </Text>
        </VStack>
      )}
    </FileUploader>
  );
}
