import { Icon } from '@chakra-ui/react';

const IconDesignCenter = ({ color }: { color?: string }) => (
  <Icon
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.75 24L7.29469 17.4553L0.75 15L7.29469 12.5447L9.75 6L12.2053 12.5447L18.75 15L12.2053 17.4553L9.75 24ZM4.125 8.25L3.02016 5.22984L0 4.125L3.02016 3.02016L4.125 0L5.22984 3.02016L8.25 4.125L5.22984 5.22984L4.125 8.25ZM18.75 12L17.2917 8.20828L13.5 6.75L17.2917 5.29172L18.75 1.5L20.2083 5.29172L24 6.75L20.2083 8.20828L18.75 12Z"
      fill={color || '#A3AED0'}
    />
  </Icon>
);

export default IconDesignCenter;
