export enum Event {
  ADD_ON_SELECTED = 'Add-On Selected',
  AVATAR_MENU_CLOSED = 'Avatar Menu Closed',
  AVATAR_MENU_OPENED = 'Avatar Menu Opened',
  BUTTON_CLICKED = 'Button Clicked',
  LOGGED_IN = 'Logged In',
  CONTACT_US_SELECTED = 'Contact Us Selected',
  LOGGED_OUT = 'Logged Out',
  PLAN_SELECTED = 'Plan Selected',
  SIGNED_UP = 'Signed Up',
  STAT_BOX_CLICKED = 'Stat Box Clicked',
  TASK_CLICKED = 'Task Clicked',
}
