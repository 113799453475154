import {
  Grid,
  GridItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  Box,
  VStack,
  useToast,
  Image,
  Spinner,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Design, Template } from '@/lib/types';

import { ARTBOARD_CATEGORY_OPTIONS } from '@/constants/artboards';
import { saveUserDesign } from '@/api/designs';
import { getDefaultTemplates } from '../../../api/templates';
import { useHistory } from 'react-router-dom';

const ARTBOARD_THUMBNAIL_WIDTH = 150;
const ARTBOARD_THUMBNAIL_DESKTOP_WIDTH = 180;

const getTemplatesForCategory = (category, templates) =>
  templates.filter(({ artboardCategory }) => artboardCategory === category);

type Props = {
  onCreatedDesign: (design: Design) => void;
};

const ArtboardSelector = ({ onCreatedDesign }: Props) => {
  const [selectedTemplate, setSelectedTemplate] = useState<Template>(null);
  const [templates, setTemplates] = useState<Template[]>([]);

  const [waiting, setWaiting] = useState(false);

  const toast = useToast();

  const history = useHistory();

  useEffect(() => {
    getDefaultTemplates().then((templates) => {
      setTemplates(templates);
    });
  }, []);

  const handleClose = () => {
    history.push('/');
  };

  const handleSave = async (template: Template) => {
    setSelectedTemplate(template);
    const { colors, id } = template;

    const sides = template.sides.map((side) => {
      return {
        templateSideId: side.id,
        templateSide: side,
        hasGraphics: false,
        hasText: false,
        designImage: 'default',
        previewImage: 'default',
      };
    });

    const newDesign = {
      sides,
      template: selectedTemplate,
      templateId: id,
      templateColorId: colors[0].id,
    };

    setWaiting(true);

    try {
      const savedDesign = await saveUserDesign(newDesign);

      onCreatedDesign(savedDesign);
    } catch (e) {
      toast({
        title: 'Error creating design',
        description: e.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setWaiting(false);
    }
  };

  const categoryOptions = ARTBOARD_CATEGORY_OPTIONS.filter(
    (category) => getTemplatesForCategory(category, templates).length
  );

  return (
    <Modal isOpen={true} onClose={handleClose} motionPreset="slideInBottom">
      <ModalOverlay background-color="rgba(27, 52, 97, 0.3)" background-blend-mode="multiply" />
      <ModalContent
        alignSelf="center"
        borderRadius="10px"
        p={0}
        maxH={{ base: '605px', md: '655px' }}
        maxW={{ base: '363px', md: '640px' }}
      >
        <ModalCloseButton />
        <ModalBody padding="40px 20px">
          <Text color="#000000" fontSize="18px" fontWeight={600} mb="20px">
            Choose Artboard Size
          </Text>
          <Tabs>
            <Box overflow="auto">
              <TabList mb="12px" w="max-content">
                {categoryOptions.map((category, index) => (
                  <Tab
                    color="#000000"
                    fontSize="12px"
                    fontWeight={600}
                    key={category}
                    padding="8px"
                    pl={index === 0 ? 0 : '8px'}
                    _selected={{
                      borderBottomWidth: '2px',
                      borderBottomColor: 'brand.600',
                    }}
                  >
                    {category}
                  </Tab>
                ))}
              </TabList>
            </Box>
            <TabPanels>
              {categoryOptions.map((category) => {
                const templatesForCategory = templates.filter(
                  ({ artboardCategory }) => artboardCategory === category
                );

                return (
                  <TabPanel padding="0" key={category}>
                    <Grid
                      templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' }}
                      gap="10px"
                      maxH={{ base: '370px', md: '420px' }}
                      overflow="auto"
                      padding="12px 0"
                    >
                      {templatesForCategory.map((template) => {
                        const { artboardIconUrl, artboardSizeDescription, id, name } = template;

                        const isSelected = id === selectedTemplate?.id;

                        return (
                          <GridItem
                            as="button"
                            bg="#EDF2F7"
                            border={isSelected ? '2px solid #A0AEC0' : 'none'}
                            borderRadius="8px"
                            h={{
                              base: `${ARTBOARD_THUMBNAIL_WIDTH}px`,
                              md: `${ARTBOARD_THUMBNAIL_DESKTOP_WIDTH}px`,
                            }}
                            onClick={() => handleSave(template)}
                            p="10px"
                            w={{
                              base: `${ARTBOARD_THUMBNAIL_WIDTH}px`,
                              md: `${ARTBOARD_THUMBNAIL_DESKTOP_WIDTH}px`,
                            }}
                            key={id}
                          >
                            <VStack>
                              {waiting && isSelected ? (
                                <Spinner />
                              ) : (
                                <Image src={artboardIconUrl} w="80px" />
                              )}
                              <Box>
                                <Text fontSize="sm" fontWeight={600}>
                                  {name}
                                </Text>
                                {artboardSizeDescription ? (
                                  <Text fontSize="sm">{artboardSizeDescription}</Text>
                                ) : null}
                              </Box>
                            </VStack>
                          </GridItem>
                        );
                      })}
                    </Grid>
                  </TabPanel>
                );
              })}
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ArtboardSelector;
