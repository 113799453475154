import {
  Button as ChakraButton,
  Box,
  Center,
  Hide,
  Spinner,
  Text,
  Stack,
  Show,
  useToast,
  Heading,
} from '@chakra-ui/react';

import IconBack from '@/lib/components/icons/IconBack';

import CreatorAvatar from './components/CreatorAvatar';
import { Design } from '@/lib';
import { getDesign } from '@/api/designs';
import { useEffect, useState } from 'react';

import IconVote from '@/components/remix/icons/IconVote';
import Button from '@/components/button';
import IconShare from '@/components/icons/IconShare';
import { DesignVote } from '@/components/types';
import { isEmpty } from 'lodash';
import RemixPreviewImage from '../demo/components/remix/RemixPreviewImage';

const getShareLink = (designId) => `${window.location.origin}/designs/${designId}`;

const getShareContent = (designId) => ({
  title: 'Check out this awesome remix I created on Ablo.ai',
  text: 'You can design on top of my design on Ablo.ai #AIDesign #Ablo #AI #FashionAI',
  url: getShareLink(designId),
});

interface RemixDetailsProps {
  designId: string;
  isVoting: boolean;
  onBack?: () => void;
  onVote: (designId: string) => void;
  designVotes: { [designId: string]: DesignVote[] };
  votedForDesign?: string;
}

export default function RemixDetails({
  designId,
  designVotes = {},
  isVoting,
  onBack,
  onVote,
  votedForDesign,
}: RemixDetailsProps) {
  const [design, setDesign] = useState<Design>(null);

  const [isLoading, setLoading] = useState(true);

  const toast = useToast();

  const hasVoted = !isEmpty(designVotes);

  const hasVotedForDesign = designVotes[designId]?.length;

  const canShare = navigator.share && navigator.canShare(getShareContent(designId));

  useEffect(() => {
    getDesign(designId)
      .then((design) => setDesign(design))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!votedForDesign || votedForDesign !== designId || !design) {
      return;
    }

    setDesign({ ...design, numVotes: design.numVotes + 1 });
  }, [votedForDesign]);

  const handleShare = async () => {
    try {
      await navigator.share({
        title: 'Check out this awesome design I created on Ablo.ai',
        text: 'You can design on top of my design on Ablo.ai #AIDesign #Ablo #AI #FashionAI',
        url: `${window.location.origin}?designId=${designId}`,
      });
    } catch (error) {
      toast({
        title: 'Error sharing content',
        description: error?.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const { description, name, numVotes, user } = design || {};

  if (isLoading) {
    return (
      <Center bg="transparent" h="100%">
        <Spinner thickness="1px" speed="0.65s" emptyColor="gray" color="brand.500" size="md" />
      </Center>
    );
  }

  return (
    <Box bg="#FFFFFF" h="100%" overflow="auto">
      <Hide above="md">
        <Box p="12px 20px 12px 0">
          <ChakraButton onClick={onBack} variant="ghost">
            <IconBack />
          </ChakraButton>
        </Box>
      </Hide>
      <Stack
        align="center"
        direction={{ base: 'column', md: 'row' }}
        alignItems="flex-start"
        p={{ base: '0 26px 50px 26px', md: 0 }}
      >
        {hasVotedForDesign ? (
          <Hide above="md">
            <Heading textAlign="center" fontSize="26px" fontWeight={700}>
              Congrats! Your vote is submitted!
            </Heading>
          </Hide>
        ) : null}
        <RemixPreviewImage design={design} />
        <Box w={{ base: '100%', md: 'calc(100% - 440px)' }}>
          <Show above="md">
            <Heading fontSize="26px" fontWeight={700}>
              {hasVotedForDesign
                ? 'Congrats! Your vote is submitted!'
                : hasVoted
                ? ''
                : 'Vote to win this merch for free!'}
            </Heading>
          </Show>
          <Text fontSize={{ base: '24px', md: '20px' }} fontWeight={700} mb="14px" mt="24px">
            {name}
          </Text>
          <CreatorAvatar user={user} />
          <Text
            fontSize={{ base: '16px', md: '16px' }}
            fontWeight={500}
            mt="20px"
            w={{ base: 'auto', md: '416px' }}
          >
            {description}
          </Text>
          {canShare ? (
            <Hide above="md">
              <Button secondary w="100%" h="50px" onClick={handleShare}>
                <IconShare color="#000000" mr="8px" />
                Share
              </Button>
            </Hide>
          ) : null}
          <Button
            isDisabled={!onVote}
            isLoading={isVoting}
            m="9px 0"
            maxW="416px"
            onClick={() => onVote(design.id)}
            w="100%"
            h="50px"
          >
            <IconVote color="#FFFFFF" mr="8px" />
            Vote Now
          </Button>
          <Text fontSize="md" fontWeight={500}>
            Total votes: {numVotes}
          </Text>
          <Text fontSize="md" fontWeight={500}></Text>
        </Box>
      </Stack>
    </Box>
  );
}
