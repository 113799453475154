import { CallbackFunction } from 'use-double-tap';

import { Box, Image } from '@chakra-ui/react';

import { IconCompress } from '../../../components/icons/IconCompress';

import { AbloImage } from '@/lib';

type ExpandedImageProps = {
  image: AbloImage;
  bindExpanded?: {
    onClick: CallbackFunction<Element>;
  };
  onCompress?: () => void;
};

const IMAGE_SIDE = 108;

const ExpandedImage = ({ image, bindExpanded, onCompress }: ExpandedImageProps) => (
  <Box position="relative" style={{ display: 'inline-block' }}>
    <Image
      borderRadius="5px"
      h="auto"
      w="100%"
      src={image.url}
      alt="Generated image"
      {...bindExpanded}
    />
    <Box
      position="absolute"
      top={IMAGE_SIDE / 20}
      right={IMAGE_SIDE / 20}
      onClick={onCompress}
      bg="white"
      borderRadius="4px"
      p="4px"
    >
      <IconCompress />
    </Box>
  </Box>
);

export default ExpandedImage;
