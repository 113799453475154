import { Center, Image } from '@chakra-ui/react';

import { Design } from '@/lib/types';

type DesignPreviewProps = {
  sideName: string;
  design: Design;
  width: number;
};

const DesignSideImage = ({ design, sideName, width }: DesignPreviewProps) => {
  const { sides = [] } = design;

  const designSide = sides.find(({ templateSide }) => templateSide.name === sideName) || {};

  const { previewImage } = designSide;

  return (
    <Center cursor="pointer" position="relative">
      <Image src={previewImage} width={`${width}px`} />
    </Center>
  );
};

export default DesignSideImage;
