import { Button as ChakraButton, Flex, HStack, Text } from '@chakra-ui/react';

import IconIncrease from './IconIncrease';
import IconDecrease from './IconDecrease';
import HintPopover from '../hints/HintPopover';
import { Hint, getCanvasHintProps } from '../hints/hints';

const Button = (props) => (
  <ChakraButton h="12px" maxWidth="none" variant="ghost" width="16px" {...props} />
);

interface ZoomControlProps {
  activeHint: Hint;
  zoomLevel: number;
  onZoomLevelChange: (zoomLevel: number) => void;
  onNextHint: () => void;
}

const ZoomControl = ({
  activeHint,
  zoomLevel,
  onZoomLevelChange,
  onNextHint,
}: ZoomControlProps) => {
  const hintProps = getCanvasHintProps(activeHint);

  return (
    <HintPopover
      isOpen={activeHint === Hint.PINCH_ZOOM}
      onNext={() => onNextHint()}
      offset={[0, 0]}
      {...hintProps}
    >
      <HStack
        bg="#FFFFFF"
        borderRadius="5px"
        boxShadow="0px 3.21px 4.29px 0px #72727240"
        h="40px"
        p="5px 10px"
        spacing="4px"
      >
        <Button onClick={() => onZoomLevelChange(Math.max(zoomLevel - 10, 0))}>
          <IconDecrease />
        </Button>
        <Flex bg="#F0F2F5" borderRadius="6px" h="20px" justify="center" w="56px">
          <Text as="b" color="#646A73" fontSize="13px">
            {zoomLevel}%
          </Text>
        </Flex>
        <Button onClick={() => onZoomLevelChange(Math.min(zoomLevel + 10, 100))}>
          <IconIncrease />
        </Button>
      </HStack>
    </HintPopover>
  );
};

export default ZoomControl;
