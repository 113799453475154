import { Box, VStack, useToast } from '@chakra-ui/react';

import { Design } from '@/lib';
import { updateBaseDesign } from '@/api/designs';
import { useEffect, useState } from 'react';

import Button from '@/components/button';
import FormInput from '@/components/form/FormInput';
import RemixPreviewImage from './RemixPreviewImage';
import { useLocation } from 'react-router-dom';

interface SubmitRemixFormProps {
  design: Design;
  onSubmitted: () => void;
}

export default function SubmitRemixForm({ design, onSubmitted }: SubmitRemixFormProps) {
  const [submitted, setSubmitted] = useState(false);

  const [values, setValues] = useState({ name: '', description: '' });

  const [savingDraft, setSavingDraft] = useState(false);
  const [submittingRemix, setSubmittingRemix] = useState(false);

  const toast = useToast();

  useEffect(() => {
    const { name, description } = design;

    setValues({ name, description });
  }, []);

  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  useEffect(() => {
    if (searchParams.get('fromAcme')) {
      onSubmitted();
    }
  }, []);

  const handleSubmit = async (isDraft = false) => {
    setSubmitted(true);

    const { name, description } = values;

    if (!name) {
      return;
    }

    if (isDraft) {
      setSavingDraft(true);
    } else {
      setSubmittingRemix(true);
    }

    try {
      const response = await updateBaseDesign({
        id: design.id,
        name,
        description: description || ' ',
        isPublished: true,
        redirectUrl: `${window.location.href}&fromAcme=true`,
      });

      if (isDraft) {
        toast({ title: 'Draft saved', status: 'success', duration: 2000 });
      } else {
        const { acmeMintingLink } = response;

        if (!acmeMintingLink) {
          onSubmitted();

          return;
        }

        const link = acmeMintingLink;

        window.location.href = link;
      }
    } catch (e) {
      toast({ title: 'Error saving remix', status: 'error', duration: 2000 });
    } finally {
      setSavingDraft(false);
      setSubmittingRemix(false);
    }
  };

  const { name, description } = values;

  return (
    <VStack align="center" alignItems="flex-start" p={{ base: '0 26px 50px 26px', md: '26px' }}>
      <RemixPreviewImage design={design} showMobileSize />
      <Box mt="16px" w="100%">
        <VStack spacing="12px">
          <FormInput
            error={submitted && !name ? 'Give it a name' : ''}
            name="Remix Name"
            value={name}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
          />
          <FormInput
            name="Description (optional)"
            value={description}
            onChange={(e) => setValues({ ...values, description: e.target.value })}
          />
        </VStack>
        <VStack mt="44px" spacing="12px">
          <Button isLoading={submittingRemix} onClick={() => handleSubmit()} w="100%" h="50px">
            Submit
          </Button>
          <Button
            isLoading={savingDraft}
            onClick={() => handleSubmit(true)}
            secondary
            w="100%"
            h="50px"
          >
            Save draft
          </Button>
        </VStack>
      </Box>
    </VStack>
  );
}
