import axios from 'axios';

import { useQuery } from '@tanstack/react-query';

export type LedgerPeriod = {
  startEpochSeconds: number;
  endEpochSeconds: number;
  totalCredit: number;
  totalDebit: number;
  debitsByBillableAction: { [key: string]: number };
};

const entity = `ledger`;

const CREDITS_USAGE_THIS_MONTH = 'credits-usage-this-month';
const CREDITS_USAGE_BY_MONTH = 'credits-usage-by-month';

export const useCreditsUsedByMonth = () =>
  useQuery([CREDITS_USAGE_BY_MONTH], () =>
    axios.get<LedgerPeriod[]>(`/${entity}/${CREDITS_USAGE_BY_MONTH}`).then(({ data }) => data)
  );

export const useCreditsUsedThisMonth = () =>
  useQuery([CREDITS_USAGE_THIS_MONTH], () =>
    axios.get<LedgerPeriod>(`/${entity}/${CREDITS_USAGE_THIS_MONTH}`).then(({ data }) => data)
  );
