import {
  Template as TemplateBase,
  TemplateColor as TemplateColorBase,
  TemplateColorImage as TemplateColorImageBase,
  TemplateSide as TemplateSideBase,
  User as UserBase,
} from '@/lib/types';
import { ReactNode } from 'react';
import Stripe from 'stripe';

export interface Route {
  name: string;
  defaultPath?: string;
  path: string;
  layout?: string;
  notInMenu?: boolean;
  icon?: ReactNode;
  iconActive?: ReactNode;
  component: () => ReactNode;
}

export interface Option<T> {
  name: string;
  id: T;
}

export interface UserRole {
  id: number;
  name: string;
}

export interface ApiKey {
  numCalls: number;
  id: string;
  key: string;
  lastUsedAt: Date;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: Date;
}
export interface Client {
  address: string;
  credits: number;
  customStylesPurchasedCount: number;
  name: string;
  id: string;
  domain: string;
  apiKeys: ApiKey[];
  subscriptionStatus: Stripe.Subscription.Status | 'in_house';
}

export interface User extends UserBase {
  id: string;
  name: string;
  email: string;
  firstName: string;
  lastName: string;
  roles: UserRole[];
  avatarUrl: string;
  avatarBase64?: string;
  client: Client;
  invitedBy?: User;
}

export interface Invite {
  email: string;
  id: string;
  invitedBy: User;
}

export interface TemplateColorImage extends TemplateColorImageBase {
  templateColorId?: string;
  image: string;
}

export interface TemplateColor extends Omit<TemplateColorBase, 'images'> {
  isDirty?: string;
  images?:
    | {
        [key: string]: string | TemplateColorImage;
      }
    | TemplateColorImage[];
}

export interface TemplateSize {
  id: number;
  name: string;
}

export interface TemplateSide extends TemplateSideBase {
  hasArea?: boolean;
  image?: string;
  isDeleted?: string;
  isDirty?: string;
  unit?: string;
  type?: string;
}

export interface Template extends Omit<TemplateBase, 'colors'> {
  contestEndAt?: string;
  currencyId?: number;
  isDeleted?: boolean;
  numCopies?: number;
  numRemixes?: number;
  numVotes?: number;
  sides?: TemplateSide[];
  colors?: TemplateColor[];
  previewImageUrl?: string;
  manufacturingProductId?: string;
  isDefault?: boolean;
  user?: UserBase;
}

export interface Currency {
  id: number;
  name: string;
}

export interface Size {
  id: number;
  name: string;
}

export interface TemplateFilters {
  type?: string[];
  color?: string[];
  size?: number[];
}

export enum BillableActionType {
  IMAGE_MAKER = 'Image Maker',
  PHOTO_TRANSFORMER = 'Photo Transformer',
  FONTMAKER = 'Fontmaker',
  BACKGROUND_REMOVAL = 'Background Removal',
  IMAGE_UPSCALING = 'Image Upscaling',
  CUSTOM_ART_STYLE = 'Custom Style',
}

export interface BillableAction {
  id: string;
  name: BillableActionType;
  credits: number;
}

export interface DesignVote {
  id: string;
  designId: string;
  templateId: string;
}
