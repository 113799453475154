import { Box, Center, Spinner } from '@chakra-ui/react';

import { format, fromUnixTime } from 'date-fns';

import type { Stripe } from 'stripe';

import { useInvoices } from '@/api/subscription';

import Table from '@/components/table';

import Button from '@/components/button';
import { INVOICE_COLUMNS } from './invoice-columns';

const padInvoiceNumber = (number) => {
  const SIZE = 3;

  let num = number.toString();

  while (num.length < SIZE) {
    num = '0' + num;
  }

  return num;
};

const getRows = (invoices: Stripe.Invoice[]) => {
  return invoices.map((invoice, index) => {
    const {
      created,
      description,
      amount_paid: amountPaid,
      amount_due: amountDue,
      lines: { data },
    } = invoice;

    return {
      ...invoice,
      plan: data[0]?.description || description,
      amountDue,
      amountPaid,
      invoice: `Invoice ${padInvoiceNumber(index + 1)}`,
      billingDate: format(fromUnixTime(created), 'MMM d, yyyy'),
    };
  });
};

export default function Invoices() {
  const { data: invoices = [], isLoading } = useInvoices();

  const rows = getRows(invoices);

  const columns = [
    ...INVOICE_COLUMNS,
    {
      name: 'Action',
      getActionComponent: (row) => (
        <Button as="a" href={row.invoice_pdf}>
          Download
        </Button>
      ),
    },
  ];

  return (
    <Box>
      {isLoading ? (
        <Center bg="transparent" h="calc(100vh - 100px)">
          <Spinner thickness="1px" speed="0.65s" emptyColor="gray" color="brand.500" size="md" />
        </Center>
      ) : (
        <Table columns={columns} rows={rows} title="Billing history" />
      )}
    </Box>
  );
}
