import { useState } from 'react';

import { Box, Center, HStack, Spinner } from '@chakra-ui/react';

import { groupBy, intersection } from 'lodash';

import { useHistory } from 'react-router-dom';

import { useDesignsBasic } from '@/api/designs';
import { useTemplates } from '@/api/templates';

import EmptyState from '@/components/empty-state';
import { TemplateFilters } from '@/components/types';

import { Template, TemplateColor } from '@/components/types';

import TemplateCard from '../components/TemplateCard';
import TemplateFilterPicker from '../components/TemplateFilterPicker';
import { useMe } from '../../../api/auth';

type TemplatesListProps = {
  templates: Template[];
  onAdd: () => void;
};

const matchesClothingType = (types: string[], template: Template) =>
  !types?.length || types.find((type) => type.includes(template.name));

const matchesColor = (colors: string[] = [], availableColors: TemplateColor[]) =>
  !colors.length ||
  intersection(
    colors,
    availableColors.map(({ name }) => name)
  ).length > 0;

const matchesSize = (sizes: number[] = [], template: Template) => {
  const allTemplateSizes = [];
  template.colors?.forEach((color) => {
    color.sizes?.forEach((size) => {
      allTemplateSizes.push(size.sizeId);
    });
  });
  const uniqueTemplateSizes = [...new Set(allTemplateSizes)];
  return !sizes.length || intersection(sizes, uniqueTemplateSizes).length > 0;
};

const matchesSearch = (search: string, name: string) =>
  !search || (name || '').toLowerCase().includes(search.toLowerCase());

const getTemplatesMatchingFilters = (
  templates: Template[],
  filters: TemplateFilters,
  search: string
) =>
  templates.filter((template) => {
    const { colors, name } = template;

    const { type, color, size } = filters;

    return (
      matchesClothingType(type, template) &&
      matchesColor(color, colors) &&
      matchesSize(size, template) &&
      matchesSearch(search, name)
    );
  });

const TemplatesList = ({ templates, onAdd }: TemplatesListProps) => {
  const { data: me } = useMe();
  const { data: designs } = useDesignsBasic(me.client);

  if (!templates.length) {
    return <EmptyState onAdd={onAdd} message="No template found" action="+ Add New Template" />;
  }

  const designsByTemplateId = groupBy(designs, 'garmentId');

  return (
    <Box>
      <HStack spacing="16px" wrap="wrap">
        {templates.map((template) => {
          const { id } = template;

          const designCount = designsByTemplateId[id]?.length;

          return (
            <TemplateCard key={id} designCount={designCount} template={template}></TemplateCard>
          );
        })}
      </HStack>
    </Box>
  );
};

export default function TemplateLibrary() {
  const [filters, setFilters] = useState<TemplateFilters>({});
  const [search, setSearch] = useState('');
  const { data: me } = useMe();

  const { data: allTemplates = [], isLoading } = useTemplates({ clientId: me.client.id });

  const templates = allTemplates.filter(({ isDeleted }) => !isDeleted);

  const history = useHistory();

  const handleAddNewTemplate = () => history.push('/template-library/new');

  const templatesMatchingFilters = templates
    ? getTemplatesMatchingFilters(templates, filters, search)
    : [];

  return (
    <Box>
      {isLoading ? (
        <Center bg="transparent" h="calc(100vh - 100px)">
          <Spinner thickness="1px" speed="0.65s" emptyColor="gray" color="brand.500" size="md" />
        </Center>
      ) : (
        <Box pb="100px">
          <TemplateFilterPicker
            filters={filters}
            onSelectedFilters={setFilters}
            search={search}
            onSearchChange={setSearch}
            templates={templates}
          />
          <TemplatesList onAdd={handleAddNewTemplate} templates={templatesMatchingFilters} />
        </Box>
      )}
    </Box>
  );
}
