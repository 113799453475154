import axios from 'axios';

import { FontToImageRequest } from '../lib';

export const generateImageFromFont = (params: FontToImageRequest) =>
  axios
    .post(`/fontmaker`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => data.images);
