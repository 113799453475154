import { Icon } from '@chakra-ui/react';

const IconVote = ({ color = null, ...rest }) => (
  <Icon
    width="21px"
    height="21px"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1326 2.26048L19.6853 11.4689L15.1758 11.4689V18.8089L7.23954 18.8089L7.23954 11.4689H2.57983L11.1326 2.26048ZM6.02023 9.96888H8.73954L8.73954 17.3089H13.6758V9.96888H16.2449L11.1326 4.46461L6.02023 9.96888Z"
      fill={color || 'black'}
    />
  </Icon>
);

export default IconVote;
