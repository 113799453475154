import { Box, Button, HStack, Heading, Hide, Image, Text, VStack } from '@chakra-ui/react';

import RemixesList from '@/components/remix/RemixesList';

import IconBack from '@/lib/components/icons/IconBack';
import { DesignVote, Template } from '@/components/types';
import { Design } from '@/lib';
import CreatorAvatar from './components/CreatorAvatar';

const IMAGE_MOBILE_SIZE = 395;
const IMAGE_DESKTOP_SIZE = 400;

const getStats = (template: Template) => [
  {
    name: 'Total submissions',
    value: template.numRemixes,
  },
  {
    name: 'Total votes',
    value: template.numVotes,
  },
];

interface CollectionPageProps {
  template: Template;
  onBack?: () => void;
  onSelectedDesign: (design: Design) => void;
  onVote?: (designId: string) => void;
  votingForDesign?: string;
  votingEnded?: boolean;
  designVotes: { [designId: string]: DesignVote[] };
  votedForDesign?: string;
}

export default function CollectionPage({
  template,
  votingForDesign,
  onBack,
  onSelectedDesign,
  onVote,
  votingEnded,
  designVotes,
  votedForDesign,
}: CollectionPageProps) {
  const { description = '', name, previewImages = [] } = template || {};

  return (
    <Box bg="#FFFFFF" h="100%" overflow="auto" pb="80px">
      {onBack ? (
        <Hide above="md">
          <Box p="12px 20px 12px 0">
            <Button onClick={onBack} variant="ghost">
              <IconBack />
            </Button>
          </Box>
        </Hide>
      ) : null}
      <Box position="relative">
        <Image
          objectFit="cover"
          src={previewImages[0]?.url}
          alt="Collection image"
          h={{ base: IMAGE_MOBILE_SIZE, md: IMAGE_DESKTOP_SIZE }}
          w={{ base: IMAGE_MOBILE_SIZE, md: '100%' }}
        />
        <Box
          bg="linear-gradient(180deg, rgba(0, 0, 0, 0) 7.5%, rgba(0, 0, 0, 0.8) 75%)"
          position="absolute"
          p={{ base: '30px 25px 33px 25px', md: '90px 25px 33px 60px' }}
          h="100%"
          w="100%"
          left={0}
          top={0}
        >
          <Heading color="#FFFFFF" fontSize={{ base: '32px', md: '46px' }}>
            {name}
          </Heading>
          <CreatorAvatar color="#FFFFFF" user={template.user} />
          <Text
            color="#FFFFFF"
            fontSize={{ base: '16px', md: '16px' }}
            fontWeight={500}
            mt="26px"
            w={{ base: 'auto', md: '566px' }}
          >
            {(description || '').slice(0, 200)}
          </Text>
          <HStack mt="33px" spacing="38px">
            {getStats(template).map(({ name, value }) => (
              <Box key={name}>
                <Text color="#FFFFFF" fontSize="md" fontWeight={500}>
                  {name}
                </Text>
                <Text color="#FFFFFF" fontSize="24px" fontWeight={700}>
                  {value ? value.toLocaleString() : 0}
                </Text>
              </Box>
            ))}
          </HStack>
        </Box>
      </Box>
      <VStack p={{ base: '24px 17px', md: '60px 50px 100px 50px' }}>
        <Text
          mb="24px"
          alignSelf={{ base: votingEnded ? 'flex-start' : 'center', md: 'flex-start' }}
          textAlign="center"
          fontSize="20px"
          fontWeight={700}
        >
          {votingEnded ? 'Meet The Winners!' : 'Vote to win this merch for free!'}
        </Text>
        <RemixesList
          votingForDesign={votingForDesign}
          votingEnded={votingEnded}
          onVote={onVote}
          onSelectedDesign={onSelectedDesign}
          template={template}
          designVotes={designVotes}
          votedForDesign={votedForDesign}
        />
      </VStack>
    </Box>
  );
}
