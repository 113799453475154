import { Image, Text } from '@chakra-ui/react';

export const StyleIcon = ({ name, isSelected, src }) => {
  const IMAGE_SIZE = 60;

  return (
    <>
      <Image
        border={isSelected ? '3px solid' : ''}
        borderColor="brand.600"
        borderRadius="50%"
        h={`${IMAGE_SIZE}px`}
        mb="8px"
        w={`${IMAGE_SIZE}px`}
        src={src}
        alt={name}
      />
      <Text color="#1A1A1A" fontSize="11px" align="center">
        {name}
      </Text>
    </>
  );
};
