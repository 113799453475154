import { useEffect, useState } from 'react';
import { Center, Flex, Stack, VStack, useBreakpointValue } from '@chakra-ui/react';

import DesignPreview from './DesignSideImage';
import { Design } from '@/lib/types';

interface DesignPreviewGalleryProps {
  design: Design;
}

const DesignPreviewGallery = ({ design }: DesignPreviewGalleryProps) => {
  const [selectedSide, setSelectedSide] = useState<string>(null);

  const { template } = design;

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    if (!template) {
      return;
    }
    setSelectedSide(template?.sides[0]?.name);
  }, [template]);

  return (
    <VStack align="start">
      <Flex
        align="center"
        bg="#FFFFFF"
        borderRadius="10px"
        direction={{ base: 'row', md: 'column' }}
        justify={{ base: 'space-between', md: 'center' }}
        h="100%"
        w="100%"
      >
        <DesignPreview design={design} sideName={selectedSide} width={isMobile ? 252 : 414} />
        {template.sides.length > 1 ? (
          <Stack direction={{ base: 'column', md: 'row' }} pt="24px">
            {template.sides.map((side) => (
              <Center
                as="button"
                borderRadius="4px"
                borderColor="brand.600"
                borderWidth={selectedSide === side.name ? '1px' : 0}
                key={side.name}
                onClick={() => setSelectedSide(side.name)}
                p="5px"
              >
                <DesignPreview design={design} sideName={side.name} width={isMobile ? 52 : 91} />
              </Center>
            ))}
          </Stack>
        ) : null}
      </Flex>
    </VStack>
  );
};

export default DesignPreviewGallery;
