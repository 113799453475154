import { useEffect, useState } from 'react';

import { every } from 'lodash';

import { Checkbox, Flex, HStack, Text, VStack, useToast } from '@chakra-ui/react';

import { DragDropContext, Draggable } from 'react-beautiful-dnd';

import { createTemplateSide, deleteTemplateSide, updateTemplateSide } from '@/api/templates';

import ButtonCTA from '@/components/button';
import FormInput from '@/components/form/FormInput';
import Droppable from '@/components/drag-and-drop/Droppable';
import IconDragHandle from '@/components/drag-and-drop/IconDragHandle';
import { TemplateSide, Template } from '@/components/types';

import ButtonAddListItem from '../components/ButtonAddListItem';
import ButtonDelete from '../components/ButtonDelete';
import FormContainer from '../components/FormContainer';
import { getListStyle, getItemStyle, reorder } from '../components/DragAndDrop';

import TagsInput from '@/components/form/TagsInput';

const DEFAULT_SIDE = {
  name: '',
  hasArea: false,
  left: 0,
  order: 0,
  top: 0,
  unit: null,
  width: 100,
  height: 100,
  tags: [],
};

type TemplateSidesProps = {
  onNext: (updates: object) => void;
  onUpdate: (updates: object) => void;
  sides: TemplateSide[];
  template: Template;
};

const TemplateSides = ({ onNext, onUpdate, sides: currentSides, template }: TemplateSidesProps) => {
  const [sides, setSides] = useState<TemplateSide[]>([]);
  const [waiting, setWaiting] = useState<number>(null);

  useEffect(() => {
    setSides(currentSides.length ? currentSides : [DEFAULT_SIDE]);
  }, [currentSides]);

  const toast = useToast();

  const handleNext = () => {
    onNext(sides);
  };

  const handleAddSide = () => {
    setSides([...sides, { ...DEFAULT_SIDE, order: sides.length }]);
  };

  const handleUpdateSide = (indexToUpdate, updates) => {
    const newSides = sides.map((side, index) => {
      if (index === indexToUpdate) {
        return {
          ...side,
          ...updates,
        };
      }

      return side;
    });

    setSides(newSides);

    if (!updates.isDirty) {
      onUpdate(newSides);
    }
  };

  const handleSaveUpdatesForSide = (side, index) => {
    setWaiting(index);

    updateTemplateSide(template.id, side)
      .then(() => {
        showToast();

        handleUpdateSide(index, { isDirty: false });
      })
      .finally(() => setWaiting(null));
  };

  const handleSaveNewSide = (side, index) => {
    if (!side.name) {
      return;
    }

    setWaiting(index);

    createTemplateSide(template.id, side)
      .then((newSide) => {
        showToast();

        handleUpdateSide(index, { ...newSide, isDirty: false });
      })
      .finally(() => setWaiting(null));
  };

  const handleRemoveSide = (sideToRemove) => {
    if (template.id && sideToRemove.id) {
      deleteTemplateSide(template.id, sideToRemove.id).then(() => handleSideRemoved(sideToRemove));

      return;
    }

    handleSideRemoved(sideToRemove);
  };

  const handleSideRemoved = (sideToRemove) => {
    const newSides = sides.filter((side) => {
      return side !== sideToRemove;
    });

    onUpdate(newSides);
    setSides(newSides);
  };

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newSides = reorder(sides, result.source.index, result.destination.index).map(
      (item, index) => ({
        ...item,
        order: index,
      })
    );

    Promise.all(newSides.map((side) => updateTemplateSide(template.id, side))).then(() => {
      showToast();
    });

    setSides(newSides);

    onUpdate(newSides);
  };

  const showToast = () =>
    toast({
      title: 'Changes saved',
      position: 'top',
      duration: 1000,
      status: 'success',
    });

  const isValid = every(sides, ({ name }) => name);

  return (
    <FormContainer
      description="Name at least one template side and select the type."
      onNext={!template.id && handleNext}
      nextDisabled={!isValid}
      title="Template sides"
    >
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <div style={{ display: 'none' }}>{provided.placeholder}</div>
              <VStack align="flex-start" mt="20px" spacing="20px" w="100%">
                {sides.map((side, index) => {
                  const { id, isDirty, name, hasArea, tags, type } = side;

                  return (
                    <Draggable key={`key-${id}`} draggableId={`ID-${id}`} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          {provided.placeholder}
                          <Flex justify="space-between" w="100%">
                            <HStack key={index} spacing="14px">
                              <div {...provided.dragHandleProps}>
                                <IconDragHandle />
                              </div>
                              <FormInput
                                name="Name"
                                placeholder="Ex) Front"
                                value={name}
                                onChange={(e) => {
                                  handleUpdateSide(index, {
                                    name: e.target.value.trim(),
                                    isDirty: true,
                                  });
                                }}
                              />
                              <FormInput
                                name="Type"
                                placeholder="Ex) sleeve_left"
                                value={type}
                                onChange={(e) => {
                                  handleUpdateSide(index, {
                                    type: e.target.value.trim(),
                                    isDirty: true,
                                  });
                                }}
                              />
                              <Checkbox
                                isChecked={hasArea}
                                onChange={(e) =>
                                  handleUpdateSide(index, {
                                    hasArea: e.target.checked,
                                    isDirty: true,
                                  })
                                }
                                w="120px"
                              >
                                <Text
                                  as="b"
                                  color="black.700"
                                  display="inline-block"
                                  fontWeight={700}
                                  width="100px"
                                >
                                  Design Area
                                </Text>
                              </Checkbox>
                              <TagsInput
                                name="Tags"
                                tags={tags}
                                onUpdateTags={(tags) => {
                                  handleUpdateSide(index, {
                                    tags,
                                    isDirty: true,
                                  });
                                }}
                              />
                            </HStack>
                            <HStack>
                              {template.id && (!id || isDirty) ? (
                                <ButtonCTA
                                  isDisabled={!name}
                                  isLoading={waiting === index}
                                  onClick={() =>
                                    id
                                      ? handleSaveUpdatesForSide(side, index)
                                      : handleSaveNewSide(side, index)
                                  }
                                  p={0}
                                  title="Save"
                                >
                                  Save
                                </ButtonCTA>
                              ) : null}
                              <ButtonDelete onClick={() => handleRemoveSide(side)} />
                            </HStack>
                          </Flex>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </VStack>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <ButtonAddListItem onClick={handleAddSide}>+ Add more sides</ButtonAddListItem>
    </FormContainer>
  );
};

export default TemplateSides;
