import { useEffect, useState } from 'react';

import {
  Box,
  Center,
  HStack,
  Heading,
  Hide,
  Image,
  Show,
  Spinner,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { getTemplates } from '@/api/templates';
import { DesignVote, Template, TemplateColorImage } from '@/components/types';
import { getCountdownLabel } from '@/utils/time';
import { Design, getTemplateWithPreviewImage } from '@/lib';
import Button from '@/components/button';

import RemixesList from '@/components/remix/RemixesList';

import CollectionPage from './CollectionPage';
import RemixDetails from './RemixDetails';
import RemixDetailsModal from './RemixDetailsModal';

import { useHistory, useLocation } from 'react-router-dom';
import { getDesignVotes, saveUserDesign, voteForDesign } from '@/api/designs';
import { groupBy, isEmpty } from 'lodash';

const IMAGE_MOBILE_SIZE = 394;
const IMAGE_DESKTOP_SIZE = 500;

const getVotesForDesigns = (templateId: string, designVotes: DesignVote[]) => {
  const votesForTemplate = designVotes.filter((designVote) => designVote.templateId === templateId);

  return groupBy(votesForTemplate, 'designId');
};

const getTemplatePreviewImage = (template) => {
  const { colors, sides } = template;

  return (
    template.previewImage ||
    (colors[0].images as TemplateColorImage[]).find(
      ({ templateSideId }) => templateSideId === sides[0]?.id
    )?.url
  );
};

interface HomeProps {
  onSignInToVote?: (designId: string) => void;
  onSignInToRemix?: () => void;
}

export default function Home({ onSignInToVote, onSignInToRemix }: HomeProps) {
  const [template, setTemplate] = useState<Template>(null);
  const [isLoading, setLoading] = useState(true);

  const [secondsLeftToContestEnd, setSecondsLeftToContestEnd] = useState(null);

  const [collectionId, setCollectionId] = useState(null);
  const [designId, setDesignId] = useState(null);

  const [designVotes, setDesignVotes] = useState({});
  const [votingForDesign, setVotingForDesign] = useState<string>(null);
  const [votedForDesign, setVotedForDesign] = useState<string>(null);

  const [waiting, setWaiting] = useState(false);

  const history = useHistory();

  const toast = useToast();

  const { search } = useLocation();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleVote = (designToVoteForId: string) => {
    if (onSignInToVote) {
      onSignInToVote(designToVoteForId);

      return;
    }

    setVotingForDesign(designToVoteForId);

    voteForDesign(designToVoteForId)
      .then((newDesignVote) => {
        const votesForDesign = designVotes[designToVoteForId] || [];

        setDesignVotes({
          ...designVotes,
          [designToVoteForId]: [...votesForDesign, newDesignVote],
        });

        setVotedForDesign(designToVoteForId);

        setTimeout(() => {
          setVotedForDesign(null);
        }, 1000);

        if (!designId) {
          handleSelectDesign({ id: designToVoteForId });
        }
      })
      .catch((e) => {
        const hasVoted = e.response?.status === 409;

        toast({
          position: 'top',
          title: hasVoted ? 'You already voted for this design' : 'Error voting for design',
          status: 'error',
          duration: 1000,
        });
      })
      .finally(() => {
        setVotingForDesign(null);
        setLoading(false);
      });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(search);

    setDesignId(queryParams.get('designId'));

    setCollectionId(queryParams.get('collectionId'));
  }, [search]);

  useEffect(() => {
    let intervalId;

    const queryParams = new URLSearchParams(search);

    const votingForDesign = queryParams.get('votingForDesign');
    const remix = queryParams.get('remix');

    Promise.all([getTemplates({ isFeatured: true }), getDesignVotes().catch(() => [])])
      .then(([templates, designVotes]) => {
        return getTemplateWithPreviewImage(templates[0]).then((featuredTemplate) => {
          setTemplate(featuredTemplate);

          setDesignVotes(getVotesForDesigns(featuredTemplate.id, designVotes));

          const { contestEndAt } = featuredTemplate;

          const getSeconds = () =>
            Math.floor((new Date(contestEndAt).getTime() - new Date().getTime()) / 1000);

          const secondsToEnd = getSeconds();

          if (!contestEndAt || secondsToEnd <= 0) {
            setSecondsLeftToContestEnd(0);

            return;
          }

          setSecondsLeftToContestEnd(secondsToEnd);

          intervalId = setInterval(() => {
            const seconds = getSeconds();

            setSecondsLeftToContestEnd(Math.max(seconds, 0));

            if (seconds < 0) {
              clearInterval(intervalId);
            }
          }, 1000);

          if (votingForDesign) {
            handleVote(votingForDesign);
          } else if (remix) {
            handleRemix(featuredTemplate);
          }
        });
      })
      .finally(() => {
        if (!votingForDesign) {
          setLoading(false);
        }
      });

    return () => clearInterval(intervalId);
  }, []);

  const handleSelectDesign = (design: Design | { id: string }) => {
    history.replace(`?designId=${design.id}`);
  };

  const handleCloseSubpage = () => {
    history.replace('/');
  };

  const handleGoToCollection = () => {
    history.replace(`?collectionId=${template.id}`);
  };

  const handleRemix = async (featuredTemplate?) => {
    if (onSignInToRemix) {
      onSignInToRemix();

      return;
    }

    const templateToUse = featuredTemplate || template;

    const { colors, id } = templateToUse;

    const sides = templateToUse.sides.map((side) => ({
      templateSideId: side.id,
    }));

    const params = {
      sides,
      template,
      templateId: id,
      templateColorId: colors[0].id,
    };

    setWaiting(true);

    try {
      const newDesign = await saveUserDesign(params as Design);

      history.push(`/designs/${newDesign.id}`);
    } catch (e) {
      toast({
        title: 'Error creating a remix',
        status: 'error',
        duration: 2000,
      });
    } finally {
      setWaiting(false);
      setLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Center bg="transparent" h="calc(100vh - 100px)">
        <Spinner thickness="1px" speed="0.65s" emptyColor="gray" color="brand.500" size="md" />
      </Center>
    );
  }

  const votingEnded = secondsLeftToContestEnd === 0;

  if (collectionId) {
    return (
      <CollectionPage
        votingForDesign={votingForDesign}
        template={template}
        onBack={handleCloseSubpage}
        onSelectedDesign={handleSelectDesign}
        onVote={handleVote}
        designVotes={designVotes}
        votedForDesign={votedForDesign}
        votingEnded={votingEnded}
      />
    );
  }

  if (designId && isMobile) {
    return (
      <RemixDetails
        isVoting={votingForDesign === designId}
        designVotes={designVotes}
        designId={designId}
        onBack={handleCloseSubpage}
        onVote={!votingEnded && isEmpty(designVotes) ? handleVote : null}
      />
    );
  }

  if (votingEnded) {
    return (
      <CollectionPage
        designVotes={designVotes}
        votingEnded
        template={template}
        onSelectedDesign={handleSelectDesign}
      />
    );
  }

  return (
    <Box bg="#FFFFFF" h="100%" overflow="auto" pb={{ base: '80px', md: 0 }}>
      <VStack pt="37px" spacing="24px">
        <Heading
          textAlign="center"
          fontSize={{ base: '22px', md: '40px' }}
          w={{ base: '217px', md: 'auto' }}
        >
          Create your style with {template.brand}
        </Heading>
        <Hide above="md">
          <Box>
            <Text color="#718096" fontSize="sm" fontWeight={500} textAlign="center">
              Winners will be chosen in:
            </Text>
            <Text fontSize="18px" fontWeight={500} textAlign="center">
              {getCountdownLabel(secondsLeftToContestEnd)}
            </Text>
          </Box>
        </Hide>
      </VStack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        justify="center"
        mt={{ base: 0, md: '50px' }}
      >
        <Image
          objectFit="contain"
          src={getTemplatePreviewImage(template)}
          alt="Template image"
          h={{ base: IMAGE_MOBILE_SIZE, md: IMAGE_DESKTOP_SIZE }}
          w={{ base: IMAGE_MOBILE_SIZE, md: IMAGE_DESKTOP_SIZE }}
        />
        <VStack align="flex-start" mb="16px" p="17px" maxW={{ base: 'none', md: '420px' }}>
          <VStack align="flex-start" spacing="11px" w="100%">
            <Text fontSize={{ base: 'md', md: '24px' }} fontWeight={700} textTransform="uppercase">
              {template.name}
            </Text>
            <Text fontSize={{ base: '14px', md: '16px' }} fontWeight={500}>
              {(template.description || '').slice(0, 200)}
            </Text>
            <Text color="gray.600" fontSize="sm" fontWeight={500}>
              {(template.numRemixes || 0).toLocaleString()} Remixes submitted
            </Text>
            <HStack w="100%">
              <Button
                h="50px"
                isLoading={waiting}
                onClick={() => handleRemix()}
                w={{ base: '100%', md: '100px' }}
              >
                Remix
              </Button>
              <Show above="md">
                <Button
                  h="50px"
                  secondary
                  onClick={handleGoToCollection}
                  w={{ base: '100%', md: '150px' }}
                >
                  <Text>Explore Collection</Text>
                </Button>
              </Show>
            </HStack>
          </VStack>
          <Show above="md">
            <Text fontSize="md" fontWeight={500} mt="30px" textAlign="center">
              Winners will be chosen in:
            </Text>
            <Text fontSize="24px" fontWeight={500} textAlign="center">
              {getCountdownLabel(secondsLeftToContestEnd)}
            </Text>
          </Show>
        </VStack>
      </Stack>
      <Show above="md">
        <Box borderBottom="1px solid #E2E8F0" m="50px 0" w="100%" />
      </Show>
      <VStack p={{ base: '17px', md: '0 50px 100px 50px' }}>
        <Heading
          mb="29px"
          alignSelf={{ base: 'center', md: 'flex-start' }}
          fontSize="22px"
          w={{ base: '269px', md: 'auto' }}
        >
          Vote to win free merch with this art!
        </Heading>
        <RemixesList
          designVotes={designVotes}
          maxItems={4}
          onSelectedDesign={handleSelectDesign}
          template={template}
          onVote={handleVote}
          votingEnded={votingEnded}
          votingForDesign={votingForDesign}
          votedForDesign={votedForDesign}
        />
        <Hide above="md">
          <Button h="50px" mt="33px" secondary onClick={handleGoToCollection} w="100%">
            <Text>Explore Collection</Text>
          </Button>
        </Hide>
      </VStack>
      {designId ? (
        <RemixDetailsModal
          designId={designId}
          designVotes={designVotes}
          isVoting={votingForDesign === designId}
          onClose={handleCloseSubpage}
          onVote={!votingEnded && isEmpty(designVotes) ? handleVote : null}
          votedForDesign={votedForDesign}
        />
      ) : null}
    </Box>
  );
}
