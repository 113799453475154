import Button from '../Button';
import IconArrowRight from '../icons/IconArrowRight';

export const ButtonBack = (props) => (
  <Button
    h="40px"
    border="1px solid #CBD5E0"
    borderRadius="12px"
    icon={<IconArrowRight transform="rotate(180deg)" />}
    fontSize="sm"
    variant="outlined"
    pl="8px"
    textTransform="none"
    title="Back"
    {...props}
  />
);

export default ButtonBack;
