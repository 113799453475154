import { Icon } from '@chakra-ui/react';

const IconCloseModal = ({ color = null, ...rest }) => (
  <Icon
    width="42px"
    height="42px"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M32.6001 11.9197L29.9233 9.24292L21.0903 18.0759L12.2574 9.24292L9.58057 11.9197L18.4135 20.7527L9.58057 29.5856L12.2574 32.2625L21.0903 23.4295L29.9233 32.2625L32.6001 29.5856L23.7672 20.7527L32.6001 11.9197Z"
      fill={color || 'black'}
    />
  </Icon>
);

export default IconCloseModal;
