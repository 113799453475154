import { Icon } from '@chakra-ui/react';

const IconFavorites = () => (
  <Icon
    width="29px"
    height="28px"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3755 5.32108C13.1043 4.15221 11.4404 3.50347 9.71346 3.50342C8.79942 3.50437 7.89459 3.68607 7.05105 4.03806C6.20751 4.39006 5.4419 4.9054 4.79829 5.55442C2.05312 8.31125 2.05429 12.6233 4.80062 15.3684L13.3546 23.9224C13.553 24.2713 13.9356 24.4964 14.3755 24.4964C14.5561 24.4947 14.7337 24.4506 14.8942 24.3677C15.0547 24.2848 15.1935 24.1655 15.2995 24.0193L23.9503 15.3684C26.6966 12.6221 26.6966 8.31125 23.948 5.54975C23.3047 4.90192 22.5397 4.38763 21.697 4.03645C20.8542 3.68527 19.9504 3.50412 19.0375 3.50342C17.3106 3.5037 15.6468 4.1524 14.3755 5.32108ZM22.2983 7.19942C24.1218 9.03225 24.123 11.8953 22.3006 13.7188L14.3755 21.6439L6.45029 13.7188C4.62796 11.8953 4.62912 9.03225 6.44796 7.20409C7.33462 6.32209 8.49429 5.83675 9.71346 5.83675C10.9326 5.83675 12.0876 6.32208 12.9673 7.20175L13.5506 7.78509C13.6589 7.89352 13.7874 7.97955 13.929 8.03825C14.0705 8.09694 14.2222 8.12715 14.3755 8.12715C14.5287 8.12715 14.6804 8.09694 14.8219 8.03825C14.9635 7.97955 15.092 7.89352 15.2003 7.78509L15.7836 7.20175C17.5476 5.44125 20.5366 5.44592 22.2983 7.19942Z"
      fill="black"
    />
  </Icon>
);

export default IconFavorites;
