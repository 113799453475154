import { FormLabel as FormLabelBase, Text } from '@chakra-ui/react';

type FormLabelProps = {
  isOptional?: boolean;
  children: React.ReactNode;
};

function FormLabel(props: FormLabelProps) {
  const { isOptional, children } = props;

  return (
    <FormLabelBase display="flex" fontSize="sm" fontWeight={500} color="black.700" mb="4px">
      {children}
      {!isOptional ? <Text color="brand.500">*</Text> : null}
    </FormLabelBase>
  );
}

export default FormLabel;
